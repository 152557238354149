// Helpers
import { user } from 'helpers.js';

// Firebase
import firebase from 'firebase/app';
import 'firebase/storage';

export default function viewDownloads( file ) {
	if ( ! user.isLogged() ) {
		return false;
	}

	const ref = firebase.storage().ref( decodeURIComponent( file ) );

	const urlPromise = ref.getDownloadURL();

	urlPromise.then( function( url ) {
		window.location.href = url;
	});
}
