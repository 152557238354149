// Helpers
import { db, user, loadTemplate, dateFormat, numberWithCommas } from 'helpers.js';

// Templates
import tplMovesDelete from 'templates/accounting/movesDelete.html';

export default function viewBeneficiariesView( id ) {
	var move;
	var moveDb;

	var types = {
		income: 'Ingreso',
		expense: 'Egreso',
		swap: 'Movimiento',
	};

	var subtypes = {
		'beneficiary': 'Pago beneficiario',
		'provider': 'Pago proveedor',
		'other': 'Otros',
		'bank-fees': 'Comisión banco',
		'partner': 'Ingreso Padrino',
		'donation': 'Donación puntual',
		'donation-anonymous': 'Donación anónima',
		'swap': 'Transferencia',
		'deposit-cash': 'Depósito efectivo',
		'extract': 'Estracción efectivo',
		'deposit-check': 'Depósito cheque',
		'cashing-check': 'Cobro cheque',
	};

	var paymentMethods = {
		'cash': 'Efectivo',
		'order': 'Orden pago',
		'own-check': 'Cheque Propio',
		'deposit-account': 'Depósito en cuenta',
		'third-check-income': 'Cheque',
		'kind-income': 'Especie',
		'transfer': 'Transferencia',
		'third-check-expense': 'Cheque tercero',
		'kind-expense': 'Especie',
	};

	if ( ! user.isLogged() ) {
		return false;
	}

	// Get Beneficiary instance
	moveDb = db( '/accounting/' + id + '/' );

	// Load Template
	moveDb.once( 'value' )
		.then( function( snapshot ) {
			move = snapshot.val();
			move.typeText = types[ move.type ];
			move.subtypeText = subtypes[ move.subtype ];
			move.paymentMethodText = paymentMethods[ move.paymentMethod ];
			move.date = dateFormat( move.date ) ;
			move.amount = numberWithCommas( move.amount ) ;

			loadTemplate( tplMovesDelete({ move: move }) );
		});

	// Init Events
	$( 'body' )
		.off( 'click', '.main-cancel' )
		.on( 'click', '.main-cancel', function( e ) {
			e.preventDefault();
			window.location.hash = '#/accounting/moves/edit/' + id + '/';
		});

	$( 'body' )
		.off( 'click', '.main-delete' )
		.on( 'click', '.main-delete', function( e ) {
			e.preventDefault();

			/**
			 * Third Cheks
			 */
			// Set to available third check on update.
			if ( 'third-check-expense' === move.paymentMethod ) {
				db( '/accounting/' + move.thirdCheckPayId + '/thirdCheck/status/' )
					.set( 1 );
			}

			// Set to unavailable third check on set it.
			if ( 'deposit-check' === move.paymentMethod ) {
				db( '/accounting/' + move.thirdCheckId + '/thirdCheck/status/' )
					.set( 1 );
			}

			// Set to unavailable third check on set it.
			if ( 'kind-expense' === move.paymentMethod ) {
				db( '/accounting/' + move.kindPayId + '/kind/status/' )
					.set( 1 );
			}

			moveDb.remove();

			window.location.hash = '#/accounting/moves/';
		});
}
