// Helpers
import { user } from 'helpers.js';

// Templates
import tplMain from 'templates/system/main.html';

// Styles
import 'styles/viewLogin.css';

// Images
import logoW from 'img/logo-white.png';

// Data
import menu from 'menu.json';


export default function viewLoginSuccess() {
	var lastPath;

	if ( ! user.isLogged() ) {
		return false;
	}

	$( 'body' ).removeClass();
	$( 'body' ).html( '' );

	lastPath = localStorage.getItem( 'last-path' ).substring( 1 );

	if ( 0 !== lastPath.indexOf( '#/download/' ) ) {
		$( 'body' ).html( tplMain({ logo: logoW, menu: menu }) );
	}

	window.location.hash = '' != lastPath ? lastPath : '#/';
}
