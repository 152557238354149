// Helpers
import { db, user, loadTemplate, numberWithCommas } from 'helpers.js';
import { dateFormat, waitingForLoad } from 'helpers.js';

// Templates
import tplAccOwnCheksList from 'templates/accounting/ownChecksList.html';

// Styles
import 'styles/accounting.css';

export default function viewAccountingValues() {

	/**
	 * Define vars.
	 */
	var bankAccounts = {};
	var beneficiaries = {};

	var ownChecks = [];
	var ownChecksAvailable = {};
	var ownChecksHistory = {};

	/**
	 * If logged out, cancel view.
	 */
	if ( ! user.isLogged() ) {
		return false;
	}

	/**
	 * On Load data, load Template.
	 */
	waitingForLoad( 'start', 3, function() {
		generateView();
	});


	/**
	 * Load Data
	 */
	db( '/accounting/' )
		.once( 'value' )
		.then( function( snapshot ) {
			snapshot.forEach( function( child ) {
				var check;

				if ( 'own-check' === child.val().paymentMethod ) {
					check = child.val();
					check.key = child.key;

					ownChecks.push( check );
				}
			});

			waitingForLoad( 'complete' );
		});

	db( '/tables/bankAccount/' )
		.once( 'value' )
		.then( function( snapshot ) {
			var account = null;

			snapshot.forEach( function( child ) {
				account = child.val();
				account.key = child.key;

				bankAccounts[ account.key ] = account;
			});

			waitingForLoad( 'complete' );
		});

	db( '/beneficiaries/' )
		.once( 'value' )
		.then( function( snapshot ) {
			snapshot.forEach( function( child ) {
				beneficiaries[ child.key ] = {
					'surname': child.val().surname,
					'name': child.val().name,
				};
			});

			waitingForLoad( 'complete' );
		});

	/**
	 * Events
	 */
	$( 'body' )
		.off( 'click', '.moves-table tbody tr' )
		.on( 'click', '.moves-table tbody tr', function( e ) {
			e.preventDefault();
			toggleMoveRow( $( e.currentTarget ) );
		});

	/**
	 * Functions
	 */
	function generateView() {
		var i;
		var move;
		var check;
		var temp;
		var text;

		/**
		 * Prepare Moves
		 */

		// Sort.
		ownChecks.sort( function( a, b ) {
			var x = a.date.toLowerCase();
			var y = b.date.toLowerCase();

			return x.localeCompare( y );
		});

		ownChecks = ownChecks.reverse();

		for ( i = 0; i < ownChecks.length; i++ ) {
			move = ownChecks[i];
			check = move.ownCheck;
			check.key = move.key;

			// Format.
			check.payDate = dateFormat( check.payDate );

			check.accountText = bankAccounts[ check.accountId ].entity;
			check.accountText += ' (';
			check.accountText += bankAccounts[ check.accountId ].number;
			check.accountText += ')';

			check.amount = numberWithCommas( move.amount );
			check.registerDate = dateFormat( move.date );

			if ( 'beneficiary' === move.subtype ) {
				temp = beneficiaries[ move.beneficiaryId ];
				text = temp.surname + ', ' + temp.name;

				check.details = 'Pago Beneficiario: ' + text;
			}

			if ( 'provider' === move.subtype ) {
				check.details = 'Pago Proveedor: ' + text;
			}

			if ( 'other' === move.subtype ) {
				check.details = 'Otros pagos';
			}

			if ( check.status ) {
				ownChecksAvailable[ check.key ] = check;
			} else {
				ownChecksHistory[ check.key ] = check;
			}
		}

		loadTemplate( tplAccOwnCheksList({
			ownChecksAvailable: ownChecksAvailable,
			ownChecksHistory: ownChecksHistory,
		}) );
	}

	function toggleMoveRow( $row ) {
		var $next = $row.next();

		if ( $next.hasClass( 'collapsed' ) ) {
			$next.removeClass( 'collapsed' );

		} else {
			$next.addClass( 'collapsed' );
		}
	}
}
