// Helpers
import { db, user, waitingForLoad, loadTemplate, st, generateHash, alertMsg } from 'helpers.js';

// Templates
import tplPartnerReportsForm from 'templates/partnerReports/form.html';

export default function viewPartnerReportsForm( id ) {
var report;

if ( ! user.isLogged() ) {
	return false;
}

// On Load data, load Template
waitingForLoad( 'start', 1, function() {
	loadTemplate(
		tplPartnerReportsForm({ report: report })
	);
});

// Load Template
db( '/partnerReports/' + id + '/' )
	.once( 'value' )
	.then( function( child ) {
		report = child.val();
		waitingForLoad( 'complete' );
	});

// Init Events
$( 'body' )
	.off( 'click', '.main-save' )
	.on( 'click', '.main-save', function( e ) {
		e.preventDefault();
		save();
	});

$( 'body' )
	.off( 'click', '.main-cancel' )
	.on( 'click', '.main-cancel', function( e ) {
		e.preventDefault();
		cancel();
	});

$( 'body' )
	.off( 'click', '.delete' )
	.on( 'click', '.delete', function( e ) {
		e.preventDefault();
		window.location.hash = '#/partner-reports/delete/' + id + '/';
	});

// Functions
function save() {
	var data = {};
	var filePdfName;
	var filePdfRef;
	var fileWordName;
	var fileWordRef;
	var msg;
	var newReport;
	var storageRef;

	var date = $( 'input[name=date]' ).val();
	var period = $( 'input[name=period]' ).val();
	var summary = $( 'textarea[name=summary]' ).val();
	var filePdf = '';
	var fileWord = '';

	if ( undefined !== id ) {
		data = report;
	}

	if ( undefined === id ) {
		filePdf = $( 'input[name=file-pdf]' )[0].files[0];
		fileWord = $( 'input[name=file-word]' )[0].files[0];

	}

	/**
	 * Validation and set data.
	 */
	if ( '' !== date ) {
		data.date = date;
	} else {
		msg = 'Debe ingregsar una fecha del informe.';
		alertMsg( '#move-form-response', 'danger', msg );

		return false;
	}

	if ( '' !== period ) {
		data.period = period;
	} else {
		msg = 'Debe ingregsar una período del informe.';
		alertMsg( '#move-form-response', 'danger', msg );

		return false;
	}

	if ( '' !== summary ) {
		data.summary = summary;
	} else {
		msg = 'Debe ingregsar una resumen del informe.';
		alertMsg( '#move-form-response', 'danger', msg );

		return false;
	}

	if ( undefined === id ) {
		if ( undefined === fileWord ) {
			msg = 'Debe seleccionar el archivo Word.';
			alertMsg( '#move-form-response', 'danger', msg );

			return false;
		}

		if ( undefined === filePdf ) {
			msg = 'Debe ingregsar una resumen del informe.';
			alertMsg( '#move-form-response', 'danger', msg );

			return false;
		}

		msg = '<strong>Subiendo archivos ';
		msg += '<i class="fas fa-spinner fa-spin"></i></strong>';
		alertMsg( '#move-form-response', 'warning', msg );

		// Upload files.
		storageRef = st( 'partnerReports' );

		fileWordName = 'informe-' + data.date + '-';
		fileWordName += generateHash().substring( 0, 4 ) + '.';
		fileWordName += fileWord.name.split( '.' ).pop().toLowerCase();

		filePdfName = 'informe-' + data.date + '-';
		filePdfName += generateHash().substring( 0, 4 ) + '.';
		filePdfName += filePdf.name.split( '.' ).pop().toLowerCase();

		fileWordRef = storageRef.child( fileWordName );
		filePdfRef = storageRef.child( filePdfName );

		fileWordRef
			.put( fileWord )
			.then( function( downloadURL ) {
				data.fileWordPath = downloadURL.metadata.fullPath;
				data.fileWordName = fileWordName;

				filePdfRef
					.put( filePdf )
					.then( function( downloadURL ) {
						data.filePdfPath = downloadURL.metadata.fullPath;
						data.filePdfName = filePdfName;

						if ( undefined !== id ) {
							newReport = db( '/partnerReports/' + id + '/' );

						} else {
							newReport = db( '/partnerReports/' ).push();
						}

						newReport.set( data ).then( function() {
							window.location.hash = '#/partner-reports/';
						});
					});
			});

	} else {
		if ( undefined !== id ) {
			newReport = db( '/partnerReports/' + id + '/' );

		} else {
			newReport = db( '/partnerReports/' ).push();
		}

		newReport.set( data ).then( function() {
			window.location.hash = '#/partner-reports/';
		});
	}

}

function cancel() {
		window.location.hash = '#/partner-reports/';
}

}
