// Helpers
import { db, user, loadTemplate, numberWithCommas } from 'helpers.js';
import { dateFormat, waitingForLoad } from 'helpers.js';

// Templates
import tplAccountingMoves from 'templates/accounting/movesList.html';

// Styles
import 'styles/accounting.css';

export default function viewAccounting() {

	/**
	 * Define vars.
	 */
	var beneficiaries = {};
	var bankAccounts = {};
	var partners = {};
	var thirdChecks = {};
	var kinds = {};

	var moves = [];

	var types = {
		income: 'Ingreso',
		expense: 'Egreso',
		swap: 'Movimiento',
	};

	var subtypes = {
		'beneficiary': 'Pago beneficiario',
		'provider': 'Pago proveedor',
		'other': 'Otros',
		'bank-fees': 'Comisión banco',
		'partner': 'Ingreso Padrino',
		'donation': 'Donación puntual',
		'donation-anonymous': 'Donación anónima',
		'swap': 'Transferencia',
		'deposit-cash': 'Depósito efectivo',
		'extract': 'Estracción efectivo',
		'deposit-check': 'Depósito cheque',
		'cashing-check': 'Cobro cheque',
	};

	var paymentMethods = {
		'cash': 'Efectivo',
		'order': 'Orden pago',
		'own-check': 'Cheque Propio',
		'deposit-account': 'Depósito en cuenta',
		'third-check-income': 'Cheque',
		'kind-income': 'Especie',
		'transfer': 'Transferencia',
		'third-check-expense': 'Cheque tercero',
		'kind-expense': 'Especie',
	};


	/**
	 * If logged out, cancel view.
	 */
	if ( ! user.isLogged() ) {
		return false;
	}

	/**
	 * On Load data, load Template.
	 */
	waitingForLoad( 'start', 5, function() {
		generateView();
	});


	/**
	 * Load Data
	 */
	db( '/beneficiaries/' )
		.once( 'value' )
		.then( function( snapshot ) {
			snapshot.forEach( function( child ) {
				beneficiaries[ child.key ] = {
					'surname': child.val().surname,
					'name': child.val().name,
				};
			});

			waitingForLoad( 'complete' );
		});

	db( '/partners/' )
		.once( 'value' )
		.then( function( snapshot ) {
			snapshot.forEach( function( child ) {
				partners[ child.key ] = {
					'name': child.val().name,
				};
			});

			waitingForLoad( 'complete' );
		});

	db( '/tables/bankAccount/' )
		.once( 'value' )
		.then( function( snapshot ) {
			snapshot.forEach( function( child ) {
				bankAccounts[ child.key ] = child.val();
			});

			waitingForLoad( 'complete' );
		});

	db( '/accounting/' )
		.once( 'value' )
		.then( function( snapshot ) {
			snapshot.forEach( function( child ) {
				if ( 'third-check-income' === child.val().paymentMethod ) {
					thirdChecks[ child.key ] = child.val();
				}

				if ( 'kind-income' === child.val().paymentMethod ) {
					kinds[ child.key ] = child.val();
				}
			});

			waitingForLoad( 'complete' );
		});

	db( '/accounting/' )
		.once( 'value' )
		.then( function( snapshot ) {
			var move;

			snapshot.forEach( function( child ) {
				move = child.val();
				move.key = child.key;

				moves.push( move );
			});

			waitingForLoad( 'complete' );
		});


	/**
	 * Events
	 */
	$( 'body' )
		.off( 'click', '#go-accounting-move' )
		.on( 'click', '#go-accounting-move', function( e ) {
			e.preventDefault();
			window.location.hash = '#/accounting/moves/add/';
		});

	$( 'body' )
		.off( 'click', '.moves-table tbody tr' )
		.on( 'click', '.moves-table tbody tr', function( e ) {
			e.preventDefault();
			toggleMoveRow( $( e.currentTarget ) );
		});

	$( 'body' )
		.off( 'click', '#moves-list tbody tr td .edit' )
		.on( 'click', '#moves-list tbody tr td .edit', function( e ) {
			var id;

			e.preventDefault();

			id = $( e.target ).parents( 'tr' ).prev().data( 'id' );
			window.location.hash = '#/accounting/moves/edit/' + id + '/';
		});


	/**
	 * Functions
	 */
	function generateView() {
		var i;
		var move;
		var temp;
		var text;

		/**
		 * Prepare Moves
		 */

		// Sort.
		moves.sort( function( a, b ) {
			var x = a.date.toLowerCase();
			var y = b.date.toLowerCase();

			return x.localeCompare( y );
		});

		moves = moves.reverse();

		for ( i = 0; i < moves.length; i++ ) {
			move = moves[i];

			// Format.
			move.typeText = types[ move.type ];
			move.subtypeText = subtypes[ move.subtype ];
			move.paymentMethodText = paymentMethods[ move.paymentMethod ];
			move.date = dateFormat( move.date ) ;
			move.amount = numberWithCommas( move.amount ) ;

			if (
				undefined !== move.beneficiaryId &&
				'' !== move.beneficiaryId
			) {
				temp = beneficiaries[ move.beneficiaryId ];
				move.beneficiaryText = temp.surname + ', ' + temp.name;
			}

			if (
				undefined !== move.partnerId &&
				'' !== move.partnerId
			) {
				temp = partners[ move.partnerId ];
				move.partnerText = temp.name;
			}

			if (
				undefined !== move.originAccountId &&
				'' !== move.originAccountId
			) {
				temp = bankAccounts[ move.originAccountId ];
				move.originAccountText = temp.entity + ' (' + temp.number + ')';
			}

			if (
				undefined !== move.targetAccountId &&
				'' !== move.targetAccountId
			) {
				temp = bankAccounts[ move.targetAccountId ];
				move.targetAccountText = temp.entity + ' (' + temp.number + ')';
			}

			if (
				undefined !== move.depositAccountId &&
				'' !== move.depositAccountId
			) {
				temp = bankAccounts[ move.depositAccountId ];
				text = temp.entity + ' (' + temp.number + ')';
				move.depositAccountText = text;
			}

			if (
				undefined !== move.transferAccountId &&
				'' !== move.transferAccountId
			) {
				temp = bankAccounts[ move.transferAccountId ];
				text = temp.entity + ' (' + temp.number + ')';
				move.transferAccountText = text;
			}

			if (
				undefined !== move.thirdCheck &&
				'' !== move.thirdCheck
			) {
				move.thirdCheck.payDate = dateFormat( move.thirdCheck.payDate );
				text = 'Banco ' + move.thirdCheck.bank + ' Nro. ';
				text = text + move.thirdCheck.number + ' (';
				text = text + move.thirdCheck.payDate + ')';

				move.thirdCheck.text = text;
			}

			if (
				undefined !== move.kind &&
				'' !== move.kind
			) {
				move.payKindText = move.kind.description;
			}

			if ( undefined !== move.order ) {
				move.order.issuedDate = dateFormat( move.order.issuedDate );

				if ( '' !== move.order.accountId ) {
					temp = bankAccounts[ move.order.accountId ];
					text = temp.entity + ' (' + temp.number + ')';
					move.order.accountText = text;
				}

				text = move.order.accountText + ' Nro. ' + move.order.number;
				text = text + ' - ' + move.order.issuedDate;
				move.order.text = text;
			}

			if ( undefined !== move.ownCheck ) {
				move.ownCheck.payDate = dateFormat( move.ownCheck.payDate );

				if ( '' !== move.ownCheck.accountId ) {
					temp = bankAccounts[ move.ownCheck.accountId ];
					text = temp.entity + ' (' + temp.number + ')';
					move.ownCheck.accountText = text;
				}

				text = move.ownCheck.accountText + ' Nro. ';
				text = text + move.ownCheck.number + ' - ';
				text = text + move.ownCheck.payDate;

				move.ownCheck.text = text;
			}

			if (
				undefined !== move.payThirdCheckId &&
				'' !== move.payThirdCheckId
			) {

				temp = thirdChecks[ move.payThirdCheckId ].thirdCheck;
				text = 'Banco ' + temp.bank + ' Nro. ' + temp.number + ' (';
				text = text + dateFormat( temp.payDate ) + ')';
				move.payThirdCheckText = text;
			}

		if (
				undefined !== move.thirdCheckId &&
				'' !== move.thirdCheckId
			) {

				temp = thirdChecks[ move.thirdCheckId ].thirdCheck;
				text = 'Banco ' + temp.bank + ' Nro. ' + temp.number + ' (';
				text = text + dateFormat( temp.payDate ) + ')';
				move.thirdCheckText = text;
			}

			if (
				undefined !== move.payKindId &&
				'' !== move.payKindId
			) {
				temp = kinds[ move.payKindId ].kind;
				move.payKindText = temp.description;
			}


			if ( 'beneficiary' === move.subtype ) {
				move.description = move.beneficiaryText;
			}

			if ( 'partner' === move.subtype ) {
				move.description = move.partnerText;
			}

			if ( 'donation' === move.subtype ) {
				move.description = move.donor;
			}

			if ( 'provider' === move.subtype ) {
				move.description = move.provider;
			}

			if (
				'bank-fees' === move.subtype ||
				'donation-anonymous' === move.subtype ||
				'deposit-cash' === move.subtype ||
				'swap' === move.subtype ||
				'extract' === move.subtype ||
				'deposit-check' === move.subtype ||
				'cashing-check' === move.subtype ||
				'other' === move.subtype
			) {
				move.description = '-';
			}

			if ( 'bank-fees' === move.subtype ) {
				move.paymentMethodText = move.originAccountText;

			} else if ( 'swap' === move.subtype ) {
				text = move.originAccountText + ' > ' + move.targetAccountText;
				move.paymentMethodText = text;

			} else if ( 'deposit-cash' === move.subtype ) {
				move.paymentMethodText = move.targetAccountText;

			} else if ( 'extract' === move.subtype ) {
				move.paymentMethodText = move.originAccountText;

			} else if ( 'deposit-check' === move.subtype ) {
				text = move.targetAccountText;
				move.paymentMethodText = text;

			} else if ( 'cashing-check' === move.subtype ) {
				move.paymentMethodText = move.payThirdCheckText + ' > Efectivo';

			} else {
				if ( 'deposit-account' === move.paymentMethod ) {
					move.paymentMethodText += ' - ' + move.depositAccountText;
				}

				if ( 'transfer' === move.paymentMethod ) {
					move.paymentMethodText += ' - ' + move.transferAccountText;
				}

				if ( 'order' === move.paymentMethod ) {
					move.paymentMethodText += ' Nro. ' + move.order.number;
					move.paymentMethodText += ' - ' + move.order.accountText;
				}
			}

			moves[i] = move;
		}

		loadTemplate( tplAccountingMoves({
			moves: moves,
		}) );
	}

	function toggleMoveRow( $row ) {
		var $next = $row.next();

		if ( $next.hasClass( 'collapsed' ) ) {
			$next.removeClass( 'collapsed' );

		} else {
			$next.addClass( 'collapsed' );
		}
	}
}
