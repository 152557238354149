var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"form-signin\" class=\"form-signin\">\n	<img class=\"mb-4 logo\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"logo") || (depth0 != null ? lookupProperty(depth0,"logo") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"logo","hash":{},"data":data,"loc":{"start":{"line":2,"column":29},"end":{"line":2,"column":39}}}) : helper)))
    + "\" alt=\"Logo Atenea\">\n	\n	<div class=\"alert alert-danger\" role=\"alert\" id=\"form-signin-alert\" style=\"display:none;\"></div>\n\n	<label for=\"form-signin-email\" class=\"sr-only\">Email</label>\n	<input type=\"email\" id=\"form-signin-email\" class=\"form-control\" placeholder=\"Email\" required autofocus>\n	\n	<label for=\"form-signin-pass\" class=\"sr-only\">Contraseña</label>\n	<input type=\"password\" id=\"form-signin-pass\" class=\"form-control\" placeholder=\"Contraseña\" required>\n\n	<button class=\"btn btn-lg btn-primary btn-block\" type=\"submit\">Entrar</button>\n\n	<p class=\"mt-5 mb-3 text-muted text-center\">Desarrollado por <a href=\"https://www.panuweb.com/\">Mauricio Panuncio</a></p>\n</form>";
},"useData":true});