var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<tr data-id=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data,"loc":{"start":{"line":197,"column":16},"end":{"line":197,"column":24}}}) : helper)))
    + "\">\n				<td data-key=\"relation\" data-value=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"relation") : depth0), depth0))
    + "\">"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"relation") : depth0), depth0))
    + "</td>\n				<td data-key=\"name\" data-value=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</td>\n				<td><button type=\"button\" class=\"btn btn-danger btn-sm delete\"><i class=\"fas fa-trash-alt\"></i></button></td>\n			</tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<tr data-id=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data,"loc":{"start":{"line":252,"column":16},"end":{"line":252,"column":24}}}) : helper)))
    + "\">\n				<td data-key=\"type\" data-value=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\">"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "</td>\n				<td data-key=\"street1\" data-value=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"street1") : depth0), depth0))
    + "\">"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"street1") : depth0), depth0))
    + "</td>\n				<td data-key=\"street2\" data-value=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"street2") : depth0), depth0))
    + "\">"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"street2") : depth0), depth0))
    + "</td>\n				<td data-key=\"zipcode\" data-value=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"zipcode") : depth0), depth0))
    + "\">"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"zipcode") : depth0), depth0))
    + "</td>\n				<td data-key=\"city\" data-value=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"city") : depth0), depth0))
    + "\">"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"city") : depth0), depth0))
    + "</td>\n				<td data-key=\"state\" data-value=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"state") : depth0), depth0))
    + "\">"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"state") : depth0), depth0))
    + "</td>\n				<td data-key=\"country\" data-value=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"country") : depth0), depth0))
    + "\">"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"country") : depth0), depth0))
    + "</td>\n				<td><button type=\"button\" class=\"btn btn-danger btn-sm delete\"><i class=\"fas fa-trash-alt\"></i></button></td>\n			</tr>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<tr data-id=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data,"loc":{"start":{"line":303,"column":16},"end":{"line":303,"column":24}}}) : helper)))
    + "\">\n				<td data-key=\"type\" data-value=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\">"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "</td>\n				<td data-key=\"value\" data-value=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\">"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "</td>\n				<td><button type=\"button\" class=\"btn btn-danger btn-sm delete\"><i class=\"fas fa-trash-alt\"></i></button></td>\n			</tr>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<tr data-id=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data,"loc":{"start":{"line":346,"column":16},"end":{"line":346,"column":24}}}) : helper)))
    + "\">			\n				<td data-key=\"type\" data-value=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\">"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "</td>\n				<td data-key=\"value\" data-value=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\">"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "</td>\n				<td><button type=\"button\" class=\"btn btn-danger btn-sm delete\"><i class=\"fas fa-trash-alt\"></i></button></td>\n			</tr>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<tr data-id=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data,"loc":{"start":{"line":401,"column":16},"end":{"line":401,"column":24}}}) : helper)))
    + "\">\n				<td data-key=\"name\" data-value=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</td>\n				<td data-key=\"file\" data-value=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"file") : depth0), depth0))
    + "\"><a href=\"/#/download/"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"file") : depth0), depth0))
    + "/\" target=\"_blank\"><i class=\"fas fa-paperclip\"></i></a></td>\n				<td><button type=\"button\" class=\"btn btn-danger btn-sm delete\"><i class=\"fas fa-trash-alt\"></i></button></td>\n			</tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom\">\n	<h1 class=\"h2\">Agregar de Beneficiario</h1>\n\n	<div class=\"mr-2\">\n		<button class=\"btn btn-sm btn-outline-success main-save\">Guardar</button>\n		<button class=\"btn btn-sm btn-outline-secondary main-cancel\">Cancelar</button>\n	</div>\n</div>\n\n\n<div class=\"col-md-8 order-md-1\">\n<form class=\"needs-validation was-validated\">\n	<h4 class=\"mb-3\">General</h4>\n	\n	<div class=\"row\">\n		<div class=\"col-md-6 mb-3\">\n			<label for=\"name\">Nombres</label>\n			<input type=\"text\" class=\"form-control\" name=\"name\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" required>\n		</div>\n	\n		<div class=\"col-md-6 mb-3\">\n			<label for=\"surname\">Apellidos</label>\n			<input type=\"text\" class=\"form-control\" name=\"surname\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"surname") : stack1), depth0))
    + "\" required>\n		</div>\n	</div>\n\n	<div class=\"row\">\n		<div class=\"col-md-6 mb-3\">\n			<label for=\"firstName\">DNI</label>\n			<input type=\"text\" pattern=\"\\d*\" maxlength=\"8\" class=\"form-control\" name=\"dni\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"dni") : stack1), depth0))
    + "\" required>\n		</div>\n	\n		<div class=\"col-md-6 mb-3\">\n			<label for=\"cuil\">CUIL</label>\n			<input type=\"text\" pattern=\"\\d*\" maxlength=\"11\" class=\"form-control\" name=\"cuil\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"cuil") : stack1), depth0))
    + "\" required>\n		</div>\n	</div>\n\n	<div class=\"row\">\n		<div class=\"col-md-6 mb-3\">\n			<label for=\"birthdate\">Fecha de Nacimiento</label>\n			<input type=\"date\" class=\"form-control\" name=\"birthdate\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"birthdate") : stack1), depth0))
    + "\" required>\n		</div>\n	\n		<div class=\"col-md-6 mb-3\">\n			<label for=\"civilStatus\">Estado Civil</label>\n			<select class=\"custom-select d-block w-100\" name=\"civilStatus\" required value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"civilStatus") : stack1), depth0))
    + "\">\n				<option value=\"\">Elija</option>\n				<option>Soltero</option>\n				<option>Casado</option>\n				<option>Divorciado</option>\n				<option>Viudo</option>\n				<option>Convivencia</option>\n			</select>\n		</div>\n	</div>\n\n	<div class=\"row\">\n		<div class=\"col-md-12 mb-3\">\n			<label for=\"job\">Ocupación</label>\n			<input type=\"text\" class=\"form-control\" name=\"job\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"job") : stack1), depth0))
    + "\" required>\n		</div>\n	</div>\n\n	<div class=\"row\">\n		<div class=\"col-md-12 mb-3\">\n			<label for=\"social\">Beneficio Social</label>\n			<input type=\"text\" class=\"form-control\" name=\"social\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"social") : stack1), depth0))
    + "\">\n		</div>\n	</div>\n	\n	<div class=\"row\">\n		<div class=\"col-md-12 mb-3\">\n			<label for=\"home\">Vivienda</label>\n			<textarea class=\"form-control\" name=\"home\" rows=\"3\" required>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"home") : stack1), depth0))
    + "</textarea>\n		</div>\n	</div>\n\n\n\n	<br>\n	<br>\n	<h4 class=\"mb-3\">Beneficio</h4>\n\n	<div class=\"row\">\n		<div class=\"col-md-4 mb-3\">\n			<label for=\"benefitDateStart\">Alta</label>\n			<input type=\"date\" class=\"form-control\" name=\"benefitDateStart\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"benefit") : stack1)) != null ? lookupProperty(stack1,"dateStart") : stack1), depth0))
    + "\" required>\n		</div>\n\n		<div class=\"col-md-4 mb-3\">\n			<label for=\"benefitDateEnd\">Baja</label>\n			<input type=\"date\" class=\"form-control\" name=\"benefitDateEnd\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"benefit") : stack1)) != null ? lookupProperty(stack1,"dateEnd") : stack1), depth0))
    + "\">\n		</div>\n\n		<div class=\"col-md-4 mb-3\">\n			<label for=\"benefitStatus\">Estado</label>\n			<select class=\"custom-select d-block w-100\" name=\"benefitStatus\" required value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"benefit") : stack1)) != null ? lookupProperty(stack1,"status") : stack1), depth0))
    + "\" required>\n				<option value=\"\">Elija</option>\n				<option>Inicial</option>\n				<option>Activo</option>\n				<option>Inactivo</option>\n				<option>Finalizado</option>\n				<option>Deserción</option>\n				<option>Suspendido</option>\n			</select>\n		</div>\n	</div>\n	<div class=\"row\">\n		<div class=\"col-md-12 mb-3\">\n			<small>\n				<strong>Inicial</strong> - Beneficiario dado de alta pero aún no cobra el beneficio<br />\n				<strong>Activo</strong> - Beneficiario dado de alta y cobrando regularmente<br />\n				<strong>Inactivo</strong> - Beneficiario dado de alta, pero tiene suspendido los cobros<br />\n				<strong>Finalizado</strong> - El beneficio ha concluído por finalización de la carrera<br />\n				<strong>Deserción</strong> - El beneficio ha concluído por abandono de la carrera<br />\n				<strong>Suspendido</strong> - El beneficio ha concluído por incumplimientos del beneficiario<br />\n			</small>\n		</div>\n	</div>\n\n\n\n	<br>\n	<br>\n	<h4 class=\"mb-3\">Carrera</h4>\n\n	<div class=\"row\">\n		<div class=\"col-md-12 mb-3\">\n			<label for=\"careerCareer\">Carrera</label>\n			<input type=\"text\" class=\"form-control\" name=\"careerCareer\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"career") : stack1)) != null ? lookupProperty(stack1,"career") : stack1), depth0))
    + "\" required>\n		</div>\n	</div>\n\n	<div class=\"row\">\n		<div class=\"col-md-12 mb-3\">\n			<label for=\"careerTitle\">Título</label>\n			<input type=\"text\" class=\"form-control\" name=\"careerTitle\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"career") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n		</div>\n	</div>\n\n	<div class=\"row\">\n		<div class=\"col-md-12 mb-3\">\n			<label for=\"careerEstablishment\">Establecimiento</label>\n			<input type=\"text\" class=\"form-control\" name=\"careerEstablishment\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"career") : stack1)) != null ? lookupProperty(stack1,"establishment") : stack1), depth0))
    + "\">\n		</div>\n	</div>\n\n	<div class=\"row\">\n		<div class=\"col-md-12 mb-3\">\n			<label for=\"careerObjectives\">Objetivos personales</label>\n			<textarea class=\"form-control\" name=\"careerObjectives\" rows=\"3\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"career") : stack1)) != null ? lookupProperty(stack1,"objectives") : stack1), depth0))
    + "</textarea>\n		</div>\n	</div>\n\n\n	<br>\n	<br>\n	<h4 class=\"mb-3\">Estudios Secundarios</h4>\n\n	<div class=\"row\">\n		<div class=\"col-md-12 mb-3\">\n			<label for=\"studiesEstablishment\">Establecimiento</label>\n			<input type=\"text\" class=\"form-control\" name=\"studiesEstablishment\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"studies") : stack1)) != null ? lookupProperty(stack1,"establishment") : stack1), depth0))
    + "\">\n		</div>\n	</div>\n\n	<div class=\"row\">\n		<div class=\"col-md-12 mb-3\">\n			<label for=\"studiesTitle\">Título</label>\n			<input type=\"text\" class=\"form-control\" name=\"studiesTitle\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"studies") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n		</div>\n	</div>\n\n	<div class=\"row\">\n		<div class=\"col-md-12 mb-3\">\n			<label for=\"studiesObjectives\">Materias pendientes</label>\n			<input type=\"number\" min=\"0\" class=\"form-control\" name=\"studiesPenndings\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"studies") : stack1)) != null ? lookupProperty(stack1,"penndings") : stack1), depth0))
    + "\">\n		</div>\n	</div>\n\n\n	<br>\n	<br>\n	<h4 class=\"mb-3\">Grupo familiar</h4>\n\n	<table class=\"table table-striped table-sm\" id=\"table-family\">\n		<thead>\n			<tr>\n				<th scope=\"col\">Parentesco</th>\n				<th scope=\"col\">Nombre</th>\n				<th scope=\"col\" style=\"width: 50px;\"></th>\n			</tr>\n		</thead>\n		<tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"family") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":196,"column":3},"end":{"line":202,"column":12}}})) != null ? stack1 : "")
    + "		</tbody>\n		<tfoot>\n			<tr>\n				<td>\n					<select class=\"custom-select d-block w-100 form-control custom-select-sm\" name=\"relation\" data-validate=\"req\">\n						<option value=\"\">Elija</option>\n						<option>Padre</option>\n						<option>Madre</option>\n						<option>Hermano/a</option>\n						<option>Hijo/a</option>\n						<option>Cónyuge</option>\n						<option>Abuelo/a</option>\n						<option>Cuñado</option>\n						<option>Yerno/Nuera</option>\n						<option>Suegro/a</option>\n						<option>Nieto/a</option>\n						<option>Otros</option>\n					</select>\n				</td>\n				<td>\n					<input type=\"text\" class=\"form-control form-control-sm\" name=\"name\" data-validate=\"req\">\n				</td>\n				<td>\n					<button type=\"button\" class=\"btn btn-primary btn-sm add\"><i class=\"fas fa-plus-square\"></i></button>\n				</td>\n			</tr>\n		</tfoot>\n	</table>\n\n\n\n	<br>\n	<br>\n	<h4 class=\"mb-3\">Direcciones</h4>\n	<table class=\"table table-striped table-sm\" id=\"table-address\">\n		<thead>\n			<tr>\n				<th scope=\"col\">Tipo</th>\n				<th scope=\"col\">Dirección 1</th>\n				<th scope=\"col\">Dirección 2</th>\n				<th scope=\"col\">Cod. Pos.</th>\n				<th scope=\"col\">Ciudad</th>\n				<th scope=\"col\">Provincia</th>\n				<th scope=\"col\">País</th>\n				<th scope=\"col\" style=\"width: 50px;\"></th>\n			</tr>\n		</thead>\n		<tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"contact") : stack1)) != null ? lookupProperty(stack1,"addresses") : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":251,"column":3},"end":{"line":262,"column":12}}})) != null ? stack1 : "")
    + "		</tbody>\n		<tfoot>\n			<tr>\n				<td>\n					<select class=\"custom-select d-block w-100 form-control custom-select-sm\" name=\"type\" data-validate=\"req\">\n						<option value=\"\">Elija</option>\n						<option>Casa</option>\n						<option>Trabajo</option>\n						<option>Estudio</option>\n						<option>Familiar</option>\n						<option>Otro</option>\n					</select>\n				</td>\n				<td><input type=\"text\" class=\"form-control form-control-sm\" name=\"street1\" data-validate=\"req\"></td>\n				<td><input type=\"text\" class=\"form-control form-control-sm\" name=\"street2\"></td>\n				<td><input type=\"text\" class=\"form-control form-control-sm\" name=\"zipcode\" data-validate=\"req\"></td>\n				<td><input type=\"text\" class=\"form-control form-control-sm\" name=\"city\" data-validate=\"req\"></td>\n				<td><input type=\"text\" class=\"form-control form-control-sm\" name=\"state\" data-validate=\"req\" data-default=\"Santa Fe\" value=\"Santa Fe\"></td>\n				<td><input type=\"text\" class=\"form-control form-control-sm\" name=\"country\" data-validate=\"req\" data-default=\"Argentina\" value=\"Argentina\"></td>\n				<td><button type=\"button\" class=\"btn btn-primary btn-sm add\"><i class=\"fas fa-plus-square\"></i></button></td>\n			</tr>\n		</tfoot>\n	</table>\n\n\n\n	<br>\n	<br>\n	<h4 class=\"mb-3\">Email</h4>\n\n	<table class=\"table table-striped table-sm\" id=\"table-email\">\n		<thead>\n			<tr>\n				<th scope=\"col\">Tipo</th>\n				<th scope=\"col\">Email</th>\n				<th style=\"width: 50px;\"></th>\n			</tr>\n		</thead>\n		<tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"contact") : stack1)) != null ? lookupProperty(stack1,"emails") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":302,"column":3},"end":{"line":308,"column":12}}})) != null ? stack1 : "")
    + "		</tbody>\n		<tfoot>\n			<tr>\n				<td>\n					<select class=\"custom-select d-block w-100 form-control custom-select-sm\" name=\"type\" data-validate=\"req\">\n						<option value=\"\">Elija</option>\n						<option>Personal</option>\n						<option>Trabajo</option>\n						<option>Otro</option>\n					</select>\n				</td>\n				<td>\n					<input type=\"email\" class=\"form-control form-control-sm\" name=\"value\" data-validate=\"req\">\n				</td>\n				<td>\n					<button type=\"button\" class=\"btn btn-primary btn-sm add\"><i class=\"fas fa-plus-square\"></i></button>\n				</td>\n			</tr>\n		</tfoot>\n	</table>\n\n\n\n	<br>\n	<br>\n	<h4 class=\"mb-3\">Teléfono</h4>\n\n	<table class=\"table table-striped table-sm\" id=\"table-phone\">\n		<thead>\n			<tr data-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":338,"column":16},"end":{"line":338,"column":23}}}) : helper)))
    + "\">\n				<th scope=\"col\">Tipo</th>\n				<th scope=\"col\">Teléfono</th>\n				<th style=\"width: 50px;\"></th>\n			</tr>\n		</thead>\n		<tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"contact") : stack1)) != null ? lookupProperty(stack1,"phones") : stack1),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":345,"column":3},"end":{"line":351,"column":12}}})) != null ? stack1 : "")
    + "		</tbody>\n		<tfoot>\n			<tr>\n				<td>\n					<select class=\"custom-select d-block w-100 form-control custom-select-sm\" name=\"type\" data-validate=\"req\">\n						<option value=\"\">Elija</option>\n						<option>Celular</option>\n						<option>Fijo</option>\n						<option>Trabajo</option>\n						<option>Otro</option>\n					</select>\n				</td>\n				<td>\n					<input type=\"text\" class=\"form-control form-control-sm\" name=\"value\" data-validate=\"req\">\n				</td>\n				<td>\n					<button type=\"button\" class=\"btn btn-primary btn-sm add\"><i class=\"fas fa-plus-square\"></i></button>\n				</td>\n			</tr>\n		</tfoot>\n	</table>\n\n\n	<br>\n	<br>\n	<h4 class=\"mb-3\">Observaciones</h4>\n	<div class=\"row\">\n		<div class=\"col-md-12 mb-3\">\n			<label for=\"careerObjectives\">Datos adicionales que no contemplados en los apartados anteriores</label>\n			<textarea class=\"form-control\" name=\"observations\" rows=\"3\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"observations") : stack1), depth0))
    + "</textarea>\n		</div>\n	</div>\n\n\n\n	<br>\n	<br>\n	<h4 class=\"mb-3\">Adjuntos</h4>\n\n	<table class=\"table table-striped table-sm\" id=\"table-attachment\">\n		<thead>\n			<tr data-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":393,"column":16},"end":{"line":393,"column":23}}}) : helper)))
    + "\">\n				<th scope=\"col\">Nombre</th>\n				<th scope=\"col\">Archivo</th>\n				<th style=\"width: 50px;\"></th>\n			</tr>\n		</thead>\n		<tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"files") : stack1),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":400,"column":3},"end":{"line":406,"column":12}}})) != null ? stack1 : "")
    + "		</tbody>\n		<tfoot>\n			<tr>\n				<td><input type=\"text\" class=\"form-control form-control-sm\" name=\"name\" data-validate=\"req\"></td>\n				<td>\n					<input type=\"file\" class=\"form-control form-control-sm\" id=\"attachment\">\n					<input type=\"hidden\" class=\"form-control form-control-sm\" name=\"file\" data-validate=\"req\">\n				</td>\n				<td><button type=\"button\" class=\"btn btn-primary btn-sm add\" disabled><i class=\"fas fa-plus-square\"></i></button></td>\n			</tr>\n		</tfoot>\n	</table>\n\n	<br>\n	<button class=\"btn btn-success main-save\">Guardar</button>\n	<button class=\"btn btn-secondary main-cancel\">Cancelar</button>\n</form>\n</div>\n\n\n<br>\n<br>\n<br>";
},"useData":true});