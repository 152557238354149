var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<tr data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\">\n			<td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"registerDate") : depth0), depth0))
    + "</td>\n			<td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "</td>\n			<td class=\"text-right\">$ "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"amount") : depth0), depth0))
    + "</td>\n		</tr>\n		<tr class=\"collapsme collapsed\">\n			<td colspan=\"3\">\n				"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"details") : depth0), depth0)) != null ? stack1 : "")
    + "\n			</td>\n		</tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "		<tr>\n			<td colspan=\"7\" class=\"text-center\"> - Sin productos - </td>\n		</tr>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "		<tr>\n			<td colspan=\"7\" class=\"text-center\"> - Sin cheques - </td>\n		</tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom\">\n	<h1 class=\"h2\">Contabilidad - Inventario</h1>\n\n	<div class=\"btn-toolbar mb-2 mb-md-0\">\n		<div class=\"btn-group mr-2\">\n		</div>\n	</div>\n</div>\n\n<table class=\"table table-hover table-sm moves-table\" id=\"moves-list\">\n	<thead class=\"thead-dark\">\n		<tr>\n			<th colspan=\"5\" class=\"text-center\">Productos disponibles</th>\n		</tr>\n		<tr>\n			<th>Fecha carga</th>\n			<th>Descripción</th>\n			<th class=\"text-right\">Valor estimado</th>\n		</tr>\n	</thead>\n	<tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"kindsAvailable") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":22,"column":2},"end":{"line":37,"column":11}}})) != null ? stack1 : "")
    + "	</tbody>\n</table>\n\n<br>\n<br>\n<br>\n<table class=\"table table-hover table-sm moves-table\" id=\"moves-list\">\n	<thead class=\"thead-dark\">\n		<tr>\n			<th colspan=\"5\" class=\"text-center\">Productos entregados</th>\n		</tr>\n		<tr>\n			<th>Fecha carga</th>\n			<th>Descripción</th>\n			<th class=\"text-right\">Valor estimado</th>\n		</tr>\n	</thead>\n	<tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"kindsHistory") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":56,"column":2},"end":{"line":71,"column":11}}})) != null ? stack1 : "")
    + "	</tbody>\n</table>\n";
},"useData":true});