var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom\">\n	<h1 class=\"h2\">Registrar Movimiento Contable</h1>\n\n	<div class=\"mr-2\">\n		<button class=\"btn btn-sm btn-outline-success main-save\">Guardar</button>\n		<button class=\"btn btn-sm btn-outline-secondary main-cancel\">Cancelar</button>\n	</div>\n</div>\n\n\n<div class=\"col-md-8 order-md-1\">\n<form class=\"needs-validation was-validated\">\n	<input type=\"hidden\" name=\"move-third-check-id-prev\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"move") : depth0)) != null ? lookupProperty(stack1,"thirdCheckId") : stack1), depth0))
    + "\">\n	<input type=\"hidden\" name=\"move-pay-third-check-id-prev\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"move") : depth0)) != null ? lookupProperty(stack1,"thirdCheckPayId") : stack1), depth0))
    + "\">\n	<input type=\"hidden\" name=\"move-pay-kind-id-prev\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"move") : depth0)) != null ? lookupProperty(stack1,"kindPayId") : stack1), depth0))
    + "\">\n	\n	<div class=\"row\">\n	<div class=\"col-md-6 mb-3\">\n		<div class=\"card mb-3\">\n		<div class=\"card-body\">\n			<h5 class=\"card-title\">Movimiento</h5>\n\n			<label for=\"date\">Fecha del Movimiento</label>\n			<input type=\"date\" class=\"form-control\" name=\"move-date\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"move") : depth0)) != null ? lookupProperty(stack1,"date") : stack1), depth0))
    + "\" required>\n			\n			<br>\n			\n			<label for=\"move-type\">Tipo de movimiento</label>\n			<select class=\"custom-select d-block w-100\" id=\"move-type\" name=\"move-type\" required value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"move") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\">\n				<option value=\"\">Elija</option>\n				<option value=\"income\">Ingreso</option>\n				<option value=\"expense\">Egreso</option>\n				<option value=\"swap\">Movimiento entre cuentas</option>\n			</select>\n		</div>\n		</div>\n\n		<div class=\"card mb-3\">\n		<div class=\"card-body\">\n			<h5 class=\"card-title\">Clasificación</h5>\n			<div id=\"move-block1\" class=\"mb-4\"></div>\n			<div id=\"move-block2\"></div>\n		</div>\n		</div>\n\n		<div class=\"card mb-3\">\n		<div class=\"card-body\">\n			<h5 class=\"card-title\">Pago</h5>\n			<div id=\"move-block3\" class=\"mb-4\"></div>\n			<div id=\"move-block4\" class=\"mb-4\"></div>\n\n			<label for=\"move-amount\">Monto</label>\n			<input type=\"number\" pattern=\"\\d*\" step=\"0.01\" maxlength=\"11\" class=\"form-control\" id=\"move-amount\" name=\"move-amount\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"move") : depth0)) != null ? lookupProperty(stack1,"amount") : stack1), depth0))
    + "\" required>\n\n		</div>\n		</div>\n\n		<div class=\"card mb-3\">\n		<div class=\"card-body\">\n			<h5 class=\"card-title\">Notas</h5>\n\n			<label for=\"move-note\" class=\"sr-only\">Notas</label>\n			<input type=\"text\" class=\"form-control\" name=\"move-note\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"move") : depth0)) != null ? lookupProperty(stack1,"note") : stack1), depth0))
    + "\">\n		</div>\n		</div>\n	</div>\n	</div>\n\n	<br>\n	<div class=\"row\">\n	<div class=\"col-md-6 mb-3\">\n		<div id=\"move-form-response\"></div>\n	</div>\n	</div>\n\n	<div class=\"row\">\n	<div class=\"col-md-6 mb-3 d-flex\">\n		<button class=\"btn btn-success main-save\">Guardar</button>\n		<button class=\"btn btn-secondary main-cancel ml-2\">Cancelar</button>\n		<button class=\"btn btn-danger delete ml-auto\">Borrar</button>\n	</div>\n	</div>\n</form>\n</div>\n\n<br>\n<br>\n<br>";
},"useData":true});