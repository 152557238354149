var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "	<div class=\"row\">\n	<div class=\"col-md-6 mb-3\">\n		<label>Archivo: Word</label>\n		<div class=\"custom-file\">\n			<input type=\"file\" class=\"custom-file-input\" name=\"file-word\" required accept=\".doc,.docx,.odt\">\n			<label class=\"custom-file-label\" for=\"customFile\" data-browse=\"Elegir\">Elija archivo</label>\n		</div>\n	</div>\n	</div>\n\n	<div class=\"row\">\n	<div class=\"col-md-6 mb-3\">\n		<label>Archivo: PDF</label>\n		<div class=\"custom-file\">\n			<input type=\"file\" class=\"custom-file-input\" name=\"file-pdf\" required accept=\".pdf\">\n			<label class=\"custom-file-label\" for=\"customFile\" data-browse=\"Elegir\">Elija archivo</label>\n		</div>\n	</div>\n	</div>\n\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n	<input type=\"hidden\" name=\"file-word\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fileWordPath") || (depth0 != null ? lookupProperty(depth0,"fileWordPath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fileWordPath","hash":{},"data":data,"loc":{"start":{"line":56,"column":46},"end":{"line":56,"column":62}}}) : helper)))
    + "\">\n	<input type=\"hidden\" name=\"file-pdf\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"filePdfPath") || (depth0 != null ? lookupProperty(depth0,"filePdfPath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filePdfPath","hash":{},"data":data,"loc":{"start":{"line":57,"column":45},"end":{"line":57,"column":60}}}) : helper)))
    + "\">\n	<div class=\"row\">\n	<div class=\"col-md-6 mb-3\">\n		Archivo Word: <strong>"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"report") : depth0)) != null ? lookupProperty(stack1,"fileWordName") : stack1), depth0))
    + "</strong>\n	</div>\n	</div>\n\n	<div class=\"row\">\n	<div class=\"col-md-6 mb-3\">\n		Archivo PDF: <strong>"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"report") : depth0)) != null ? lookupProperty(stack1,"filePdfName") : stack1), depth0))
    + "</strong>\n	</div>\n	</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "		<button class=\"btn btn-danger delete ml-auto\">Borrar</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom\">\n	<h1 class=\"h2\">Agregar Informe</h1>\n\n	<div class=\"mr-2\">\n		<button class=\"btn btn-sm btn-outline-success main-save\">Guardar</button>\n		<button class=\"btn btn-sm btn-outline-secondary main-cancel\">Cancelar</button>\n	</div>\n</div>\n\n<div class=\"col-md-8 order-md-1\">\n<form class=\"needs-validation was-validated\">\n	<div class=\"row\">\n	<div class=\"col-md-6 mb-3\">\n		<label>Fecha del Informe</label>\n		<input type=\"date\" class=\"form-control\" name=\"date\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"report") : depth0)) != null ? lookupProperty(stack1,"date") : stack1), depth0))
    + "\" required>\n	</div>\n	</div>\n\n	<div class=\"row\">\n	<div class=\"col-md-6 mb-3\">\n		<label>Período</label>\n		<input type=\"text\" class=\"form-control\" name=\"period\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"report") : depth0)) != null ? lookupProperty(stack1,"period") : stack1), depth0))
    + "\" required>\n	</div>\n	</div>\n\n	<div class=\"row\">\n	<div class=\"col-md-6 mb-3\">\n		<label>Rersumen</label>\n		<textarea class=\"form-control\" name=\"summary\" rows=\"3\" required>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"report") : depth0)) != null ? lookupProperty(stack1,"summary") : stack1), depth0))
    + "</textarea>\n	</div>\n	</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"report") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":33,"column":1},"end":{"line":69,"column":12}}})) != null ? stack1 : "")
    + "\n	<br>\n	<div class=\"row\">\n	<div class=\"col-md-6 mb-3\">\n		<div id=\"move-form-response\"></div>\n	</div>\n	</div>\n\n\n	<br>\n	<div class=\"col-md-6 mb-3 d-flex\">\n		<button class=\"btn btn-success main-save\">Guardar</button>\n		<button class=\"btn btn-secondary main-cancel ml-2\">Cancelar</button>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"report") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":2},"end":{"line":86,"column":9}}})) != null ? stack1 : "")
    + "	</div>\n</form>\n</div>\n";
},"useData":true});