// Helpers
import { db, user, loadTemplate } from 'helpers.js';

// Templates
import tplPartners from 'templates/partners/list.html';

// Styles
import 'styles/partners.css';

export default function viewPartners() {
	if ( ! user.isLogged() ) {
		return false;
	}

	db( '/partners/' )
		.once( 'value' )
		.then( function( snapshot ) {
			var partners = [];

			snapshot.forEach( function( child ) {
				partners.push({
					'key': child.key,
					'name': child.val().name,
				});
			});

			partners.sort( function( a, b ) {
				var z = a.name.toLowerCase();
				var w = b.name.toLowerCase();

				return z.localeCompare( w );
			});

			loadTemplate( tplPartners({ partners: partners }) );
		});


	$( 'body' )
		.off( 'click', '#partners-list tbody tr' )
		.on( 'click', '#partners-list tbody tr', function( elm ) {
			var id = $( elm.target ).parent( 'tr' ).data( 'id' );

			window.location.hash = '#/partners/view/' + id + '/';
		});

	$( 'body' )
		.off( 'click', '#add-partner' )
		.on( 'click', '#add-partner', function( e ) {
			e.preventDefault();

			window.location.hash = '#/partners/add/';
		});
}
