// Helpers
import { user } from 'helpers.js';

// Templates
import tplLogin from 'templates/system/login.html';

// Styles
import 'styles/viewLogin.css';

// Images
import logo from 'img/logo.png';

export default function viewLogin() {
	$( 'body' ).removeClass();
	$( 'body' ).addClass( 'login' );
	$( 'body' ).html( tplLogin({ logo: logo }) );

	$( 'body' )
		.off( 'submit', '#form-signin' )
		.on( 'submit', '#form-signin', function() {
			user.logIn(
				$( '#form-signin-email' ).val(),
				$( '#form-signin-pass' ).val()
			);

			return false;
		});
}
