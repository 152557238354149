var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom\">\n	<h1 class=\"h2\">Panel</h1>\n</div>\n\n<div class=\"row\">\n	<div class=\"col-md-4\">\n		<div class=\"card text-center bg-primary text-light\">\n			<div class=\"card-body\">\n				<h5 class=\"card-title\">Total Beneficiarios</h5>\n				<p class=\"card-text display-4\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"totalBeneficiaries") || (depth0 != null ? lookupProperty(depth0,"totalBeneficiaries") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalBeneficiaries","hash":{},"data":data,"loc":{"start":{"line":10,"column":35},"end":{"line":10,"column":57}}}) : helper)))
    + "</p>\n			</div>\n		</div>\n	</div>\n	<div class=\"col-md-4\">\n		<div class=\"card text-center bg-success text-light\">\n			<div class=\"card-body\">\n				<h5 class=\"card-title\">Beneficiarios Activos</h5>\n				<p class=\"card-text display-4\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"activeBeneficiaries") || (depth0 != null ? lookupProperty(depth0,"activeBeneficiaries") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activeBeneficiaries","hash":{},"data":data,"loc":{"start":{"line":18,"column":35},"end":{"line":18,"column":58}}}) : helper)))
    + "</p>\n			</div>\n		</div>\n	</div>\n	<div class=\"col-md-4\">\n		<div class=\"card text-center bg-info text-light\">\n			<div class=\"card-body\">\n				<h5 class=\"card-title\">Total Padrinos</h5>\n				<p class=\"card-text display-4\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"partners") || (depth0 != null ? lookupProperty(depth0,"partners") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"partners","hash":{},"data":data,"loc":{"start":{"line":26,"column":35},"end":{"line":26,"column":47}}}) : helper)))
    + "</p>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div class=\"row mt-4\">\n	<div class=\"col-md-4\">\n		<div class=\"card text-center bg-danger text-light\">\n			<div class=\"card-body\">\n				<h5 class=\"card-title\">Saldo cuentas</h5>\n				<p class=\"card-text display-4\">$ 0,00</p>\n			</div>\n		</div>\n	</div>\n	<div class=\"col-md-4\">\n		<div class=\"card text-center bg-warning text-dark\">\n			<div class=\"card-body\">\n				<h5 class=\"card-title\">Cheques por vencer</h5>\n				<p class=\"card-text display-4\">-</p>\n			</div>\n		</div>\n	</div>\n	<div class=\"col-md-4\">\n		<div class=\"card text-center bg-secondary text-light\">\n			<div class=\"card-body\">\n				<h5 class=\"card-title\">Inventario</h5>\n				<p class=\"card-text display-4\">0</p>\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true});