// Helpers
import { db, user, loadTemplate, numberWithCommas } from 'helpers.js';
import { dateFormat, waitingForLoad } from 'helpers.js';

// Templates
import tplAccountingKinds from 'templates/accounting/kindsList.html';

// Styles
import 'styles/accounting.css';

export default function viewAccountingValues() {

	/**
	 * Define vars.
	 */
	var partners = {};

	var kinds = [];
	var kindsAvailable = {};
	var kindsHistory = {};


	/**
	 * If logged out, cancel view.
	 */
	if ( ! user.isLogged() ) {
		return false;
	}

	/**
	 * On Load data, load Template.
	 */
	waitingForLoad( 'start', 2, function() {
		generateView();
	});


	/**
	 * Load Data
	 */
	db( '/accounting/' )
		.once( 'value' )
		.then( function( snapshot ) {
			snapshot.forEach( function( child ) {
				var kind;

				if ( 'kind-income' === child.val().paymentMethod ) {
					kind = child.val();
					kind.key = child.key;

					kinds.push( kind );
				}
			});

			waitingForLoad( 'complete' );
		});

	db( '/partners/' )
		.once( 'value' )
		.then( function( snapshot ) {
			snapshot.forEach( function( child ) {
				partners[ child.key ] = {
					'name': child.val().name,
				};
			});

			waitingForLoad( 'complete' );
		});

	/**
	 * Events
	 */
	$( 'body' )
		.off( 'click', '.moves-table tbody tr' )
		.on( 'click', '.moves-table tbody tr', function( e ) {
			e.preventDefault();
			toggleMoveRow( $( e.currentTarget ) );
		});

	/**
	 * Functions
	 */
	function generateView() {
		var i;
		var move;
		var kind;
		var temp;

		/**
		 * Prepare Moves
		 */

		// Sort.
		kinds.sort( function( a, b ) {
			var x = a.date.toLowerCase();
			var y = b.date.toLowerCase();

			return x.localeCompare( y );
		});

		kinds = kinds.reverse();

		for ( i = 0; i < kinds.length; i++ ) {
			move = kinds[i];
			kind = move.kind;
			kind.key = move.key;

			// Format.
			kind.amount = numberWithCommas( move.amount ) ;
			kind.registerDate = dateFormat( move.date ) ;

			kind.details = '<strong>Ingreso:</strong> ';

			if ( 'partner' === move.subtype ) {
				temp = partners[ move.partnerId ].name;

				kind.details += 'Donación Padrino: ' + temp;
			}

			if ( 'donation' === move.subtype ) {
				kind.details += 'Donación: ' + move.donor;
			}

			if ( 'donation-anonymous' === move.subtype ) {
				kind.details += 'Donación Anónima';
			}

			if ( 'other' === move.subtype ) {
				kind.details += 'Otros pagos';
			}


			if ( kind.status ) {
				kindsAvailable[ kind.key ] = kind;
			} else {
				kindsHistory[ kind.key ] = kind;
			}
		}

		loadTemplate( tplAccountingKinds({
			kindsAvailable: kindsAvailable,
			kindsHistory: kindsHistory,
		}) );
	}

	function toggleMoveRow( $row ) {
		var $next = $row.next();

		if ( $next.hasClass( 'collapsed' ) ) {
			$next.removeClass( 'collapsed' );

		} else {
			$next.addClass( 'collapsed' );
		}
	}
}
