import { breadcrumb } from 'helpers.js';

import viewAccountingMovesList from 'views/accounting/movesList.js';
import viewAccountingMovesForm from 'views/accounting/movesForm.js';
import viewAccountingMovesDelete from 'views/accounting/movesDelete.js';
import viewAccountingOrders from 'views/accounting/ordersList.js';
import viewAccountingThirdChecksList from 'views/accounting/thirdChecksList.js';
import viewAccountingOwnChecksList from 'views/accounting/ownChecksList.js';
import viewAccountingKinds from 'views/accounting/kindsList.js';

export default function routeAccounting() {
	var route = window.Sammy.apps.body;

	// Moves
	route.get( '#/accounting/moves/', function() {
		viewAccountingMovesList();
		breadcrumb([ {
			n: 'Contabilidad',
		}, {
			n: 'Movimientos',
		} ]);
	});

	route.get( '#/accounting/moves/add/', function() {
		viewAccountingMovesForm();
		breadcrumb([ {
			u: '#/accounting/',
			n: 'Contabilidad',
		}, {
			u: '#/accounting/moves/',
			n: 'Movimientos',
		}, {
			n: 'Agregar',
		} ]);
	});

	route.get( '#/accounting/moves/edit/:id/', function() {
		viewAccountingMovesForm( this.params.id );
		breadcrumb([ {
			u: '#/accounting/',
			n: 'Contabilidad',
		}, {
			u: '#/accounting/moves/',
			n: 'Movimientos',
		}, {
			n: 'Editar',
		} ]);
	});

	route.get( '#/accounting/moves/delete/:id/', function() {
		viewAccountingMovesDelete( this.params.id );
		breadcrumb([ {
			u: '#/accounting/',
			n: 'Contabilidad',
		}, {
			u: '#/accounting/moves/',
			n: 'Movimientos',
		}, {
			n: 'Borrar',
		} ]);
	});

	// Values
	route.get( '#/accounting/third-checks/', function() {
		viewAccountingThirdChecksList();
		breadcrumb([ {
			n: 'Contabilidad',
		}, {
			n: 'Cheques terceros',
		} ]);
	});

	route.get( '#/accounting/orders/', function() {
		viewAccountingOrders();
		breadcrumb([ {
			n: 'Contabilidad',
		}, {
			n: 'Órdenes',
		} ]);
	});

	route.get( '#/accounting/own-checks/', function() {
		viewAccountingOwnChecksList();
		breadcrumb([ {
			n: 'Contabilidad',
		}, {
			n: 'Cheques propios',
		} ]);
	});

	// Accounting - Kinds
	route.get( '#/accounting/kinds/', function() {
		viewAccountingKinds();
		breadcrumb([ {
			n: 'Contabilidad',
		}, {
			n: 'Valores',
		} ]);
	});
}
