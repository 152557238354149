// Helpers
import { db, user, loadTemplate } from 'helpers.js';

// Templates
import tplUsers from 'templates/configs/users.html';

export default function viewUsers() {
	var users = [];

	if ( ! user.isLogged() ) {
		return false;
	}

	db( '/configs/users/' )
		.orderByKey()
		.once( 'value' )
		.then( function( snapshot ) {

			snapshot.forEach( function( child ) {
				var user = { uid: child.key };
				$.extend( user, child.val() );

				users.push( user );
			});

			loadTemplate( tplUsers({ users: users }) );
		});

}
