var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<tr>\n				<td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"date") : depth0), depth0))
    + "</td>\n				<td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"paymentMethodText") : depth0), depth0))
    + "</td>\n				<td class=\"text-right\">$ "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"amount") : depth0), depth0))
    + "</td>\n			</tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom\">\n	<h1 class=\"h2\">Contabilidad Beneficiario: "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"surname") : stack1), depth0))
    + ", "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h1>\n\n	<!--\n	<div class=\"mr-2\">\n		<button class=\"btn btn-sm btn-outline-success main-accounting\">Contabilidad</button>\n		<button class=\"btn btn-sm btn-outline-primary main-edit\">Editar</button>\n		<button class=\"btn btn-sm btn-outline-danger main-delete\">Borrar</button>\n	</div>\n	-->\n</div>\n\n<h3>Pagos Beneficiario</h3>\n\n<div class=\"row\">\n<div class=\"col-md-6\">\n	<table class=\"table\">\n		<thead>\n			<tr>\n				<th>Fecha</th>\n				<th>Metodo de pago</th>\n				<th class=\"text-right\">Monto</th>\n			</tr>\n\n		</thead>\n		<tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"moves") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":3},"end":{"line":33,"column":12}}})) != null ? stack1 : "")
    + "		</tbody>\n		<tfoot style=\"font-weight: bold; border-top: 1px solid #000;\">\n			<tr>\n				<td colspan=\"2\">Total</td>\n				<td class=\"text-right\">$ "
    + alias2(((helper = (helper = lookupProperty(helpers,"total") || (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"total","hash":{},"data":data,"loc":{"start":{"line":38,"column":29},"end":{"line":38,"column":38}}}) : helper)))
    + "</td>\n			</tr>\n		</tfoot>\n	</table>\n</div>\n</div>";
},"useData":true});