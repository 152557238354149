// Import Firebase
import firebase from 'firebase/app';


// Initialize Firebase
var config = {
	apiKey: 'AIzaSyAFJlBMjiqI_AH4h6DK8atK-3A3TWQOjTg',
	authDomain: 'atenea-909f1.firebaseapp.com',
	databaseURL: 'https://atenea-909f1.firebaseio.com',
	projectId: 'atenea-909f1',
	storageBucket: 'atenea-909f1.appspot.com',
	messagingSenderId: '444970882134',
};

firebase.initializeApp( config );


