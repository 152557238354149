// Inport FontAwesome
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import * as fasIcons from '@fortawesome/free-solid-svg-icons';
import * as farIcons from '@fortawesome/free-regular-svg-icons';

const iconsFas = [
	'faBriefcase',
	'faDownload',
	'faExchangeAlt',
	'faFilePdf',
	'faFileWord',
	'faHome',
	'faPlusSquare',
	'faSpinner',
	'faTable',
	'faTrashAlt',
	'faUser',
	'faUsers',
];

const iconsFar = [
	'faFileAlt',
	'faListAlt',
];

var i;

for ( i = 0; i < iconsFas.length; i++ ) {
	library.add( fasIcons[ iconsFas[i] ]);
}

for ( i = 0; i < iconsFar.length; i++ ) {
	library.add( farIcons[ iconsFar[i] ]);
}

dom.watch();
