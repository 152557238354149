// Helpers
import { db, user, loadTemplate, waitingForLoad, alertMsg } from 'helpers.js';

// Templates
import tplAccountingMovesForm from 'templates/accounting/movesForm.html';
import tplMovesFormBlock1 from 'templates/accounting/movesFormBlock1.html';
import tplMovesFormBlock2 from 'templates/accounting/movesFormBlock2.html';
import tplMovesFormBlock3 from 'templates/accounting/movesFormBlock3.html';
import tplMovesFormBlock4 from 'templates/accounting/movesFormBlock4.html';

// Start main function.
export default function viewAccountingMoveForm( id ) {

var currentMove;

var bankAccounts = [];
var bankAccountsIssuesOrders = [];
var bankAccountsIssuesTransfers = [];
var bankAccountsIssuesChecks = [];

var haveBankAccounts = false;
var haveMoreOneBankAccounts = false;
var haveBankAccountsIssuesOrders = false;
var haveBankAccountsIssuesTransfers = false;
var haveBankAccountsIssuesChecks = false;

var thirdChecks = [];
var thirdChecksHistory = {};
var haveThirdChecks = false;

var kinds = [];
var kindsHistory = {};
var haveKinds = false;

var beneficiaries = [];
var beneficiariesActives = [];
var beneficiariesInactives = [];
var partners = [];

if ( ! user.isLogged() ) {
	return false;
}

// On Load data, load Template
waitingForLoad( 'start', 5, function() {
	loadTemplate( tplAccountingMovesForm({
		move: currentMove,
	}) );
});

// Load data
db( '/beneficiaries/' )
	.once( 'value' )
	.then( function( snapshot ) {
		var i;

		snapshot.forEach( function( child ) {
			beneficiaries.push({
				'key': child.key,
				'surname': child.val().surname,
				'name': child.val().name,
				'dni': child.val().dni,
				'benefitStatus': child.val().benefit.status,
			});
		});

		beneficiaries.sort( function( a, b ) {
			var x = a.surname.toLowerCase();
			var y = b.surname.toLowerCase();
			var z = a.name.toLowerCase();
			var w = b.name.toLowerCase();

			if ( x === y ) {
				return z.localeCompare( w );
			}

			return x.localeCompare( y );
		});

		for ( i = 0; i < beneficiaries.length; i++ ) {
			if ( 'Activo' === beneficiaries[i].benefitStatus ) {
				beneficiariesActives.push( beneficiaries[i]);

			} else {
				beneficiariesInactives.push( beneficiaries[i]);
			}
		}

		waitingForLoad( 'complete' );
	});

db( '/tables/bankAccount/' )
	.once( 'value' )
	.then( function( snapshot ) {
		var account = null;

		snapshot.forEach( function( child ) {
			account = child.val();
			account.key = child.key;

			bankAccounts.push( account );

			if ( 1 === account.issuesOrders ) {
				bankAccountsIssuesOrders.push( account );
			}

			if ( 1 === account.issuesTransfers ) {
				bankAccountsIssuesTransfers.push( account );
			}

			if ( 1 === account.issuesCheks ) {
				bankAccountsIssuesChecks.push( account );
			}
		});


		if ( 1 <= bankAccounts.length ) {
			haveBankAccounts = true;
		}

		if ( 2 <= bankAccounts.length ) {
			haveMoreOneBankAccounts = true;
		}

		if ( 1 <= bankAccountsIssuesOrders.length ) {
			haveBankAccountsIssuesOrders = true;
		}

		if ( 1 <= bankAccountsIssuesTransfers.length ) {
			haveBankAccountsIssuesTransfers = true;
		}

		if ( 1 <= bankAccountsIssuesChecks.length ) {
			haveBankAccountsIssuesChecks = true;
		}

		waitingForLoad( 'complete' );
	});

db( '/accounting/' )
	.once( 'value' )
	.then( function( snapshot ) {
		snapshot.forEach( function( child ) {
			var move;
			var check;
			var kind;

			move = child.val();

			if ( 'third-check-income' === move.paymentMethod ) {
				check = move.thirdCheck;
				check.amount = move.amount;
				check.key = child.key;

				if ( 1 === check.status ) {
					thirdChecks.push( check );
				} else {
					thirdChecksHistory[ check.key ] = check;
				}
			}

			if ( 'kind-income' === child.val().paymentMethod ) {
				kind = move.kind;
				kind.amount = move.amount;
				kind.key = child.key;

				if ( 1 === kind.status ) {
					kinds.push( kind );

				} else {
					kindsHistory[ kind.key ] = kind;
				}
			}

		});

		if ( 1 <= thirdChecks.length ) {
			haveThirdChecks = true;
		}

		if ( 1 <= kinds.length ) {
			haveKinds = true;
		}

		waitingForLoad( 'complete' );
	});

db( '/partners/' )
	.once( 'value' )
	.then( function( snapshot ) {

		snapshot.forEach( function( child ) {
			partners.push({
				'key': child.key,
				'name': child.val().name,
			});
		});

		partners.sort( function( a, b ) {
			var z = a.name.toLowerCase();
			var w = b.name.toLowerCase();

			return z.localeCompare( w );
		});

		waitingForLoad( 'complete' );
	});

db( '/accounting/' + id + '/' )
	.once( 'value' )
	.then( function( snapshot ) {
		currentMove = snapshot.val();

		waitingForLoad( 'complete' );
	});


/**
 * Events
 */
$( 'body' )
	.off( 'click', '.main-save' )
	.on( 'click', '.main-save', function( e ) {
		e.preventDefault();
		save();
	});

$( 'body' )
	.off( 'click', '.main-cancel' )
	.on( 'click', '.main-cancel', function( e ) {
		e.preventDefault();
		cancel();
	});

$( 'body' )
	.off( 'click', '.delete' )
	.on( 'click', '.delete', function( e ) {
		e.preventDefault();
		window.location.hash = '#/accounting/moves/delete/' + id + '/';
	});

$( 'body' )
	.off( 'change', '#move-type' )
	.on( 'change', '#move-type', function() {
		changeMoveType();
	});

$( 'body' )
	.off( 'change', '#move-subtype' )
	.on( 'change', '#move-subtype', function() {
		changeMoveSubtype();
	});

$( 'body' )
	.off( 'change', '#payment-method' )
	.on( 'change', '#payment-method', function() {
		changeMovePaymentMethod();
	});

$( 'body' )
	.off( 'change', '[name="move-pay-third-check-id"]' )
	.on( 'change', '[name="move-pay-third-check-id"]', function( e ) {
		var amount = $( e.currentTarget ).children( 'option:selected' ).data( 'amount' );
		$( '[name="move-amount"]' ).val( amount );
	});

$( 'body' )
	.off( 'change', '[name="move-pay-kind-id"]' )
	.on( 'change', '[name="move-pay-kind-id"]', function( e ) {
		var amount = $( e.currentTarget ).children( 'option:selected' ).data( 'amount' );
		$( '[name="move-amount"]' ).val( amount );
	});

$( 'body' )
	.off( 'change', '[name="move-third-check-id"]' )
	.on( 'change', '[name="move-third-check-id"]', function( e ) {
		var amount = $( e.currentTarget ).children( 'option:selected' ).data( 'amount' );
		$( '[name="move-amount"]' ).val( amount );
	});


/**
 * Functions
 */
function changeMoveType() {
	var moveType = $( '#move-type' ).val();
	var moveTypeExpense = false;
	var moveTypeIncome = false;
	var moveTypeSwap = false;
	var moveTypeExpenseIncome = false;

	if ( 'income' === moveType ) {
		moveTypeIncome = true;
		moveTypeExpenseIncome = true;
	}

	if ( 'expense' === moveType ) {
		moveTypeExpense = true;
		moveTypeExpenseIncome = true;
	}

	if ( 'swap' === moveType ) {
		moveTypeSwap = true;
	}

	if ( '' !== moveType ) {
		loadTemplate(
			tplMovesFormBlock1({
				'meta': {
					'moveTypeExpense': moveTypeExpense,
					'moveTypeIncome': moveTypeIncome,
					'moveTypeSwap': moveTypeSwap,
					'moveTypeExpenseIncome': moveTypeExpenseIncome,
					'haveBankAccounts': haveBankAccounts,
					'haveMoreOneBankAccounts': haveMoreOneBankAccounts,
					'haveThirdChecks': haveThirdChecks,
				},
				move: currentMove,
			}),
			'#move-block1'
		);
	} else {
		loadTemplate( '', '#move-block1' );
	}

	loadTemplate( '', '#move-block2' );
	loadTemplate( '', '#move-block3' );
	loadTemplate( '', '#move-block4' );
}


function changeMoveSubtype() {
	var metaTemplateBlock3;

	var moveType = $( '#move-type' ).val();
	var moveTypeExpense = false;
	var moveTypeIncome = false;
	var moveTypeSwap = false;

	var moveSubtype = $( '#move-subtype' ).val();
	var moveSubtypeBeneficiary = false;
	var moveSubtypeProvider = false;
	var moveSubtypePartner = false;
	var moveSubtypeDonation = false;

	var targetAccount = false;
	var originAccount = false;
	var getThirdCheck = false;
	var showPayment = false;

	if ( 'income' === moveType ) {
		moveTypeIncome = true;
	}

	if ( 'expense' === moveType ) {
		moveTypeExpense = true;
	}

	if ( 'swap' === moveType ) {
		moveTypeSwap = true;
	}


	if ( 'beneficiary' === moveSubtype ) {
		showPayment = true;
		moveSubtypeBeneficiary = true;
	}

	if ( 'provider' === moveSubtype ) {
		showPayment = true;
		moveSubtypeProvider = true;
	}

	if ( 'bank-fees' === moveSubtype ) {
		originAccount = true;
	}

	if ( 'partner' === moveSubtype ) {
		showPayment = true;
		moveSubtypePartner = true;
	}

	if ( 'donation' === moveSubtype ) {
		showPayment = true;
		moveSubtypeDonation = true;
	}

	if ( 'donation-anonymous' === moveSubtype ) {
		showPayment = true;
	}

	if ( 'swap' === moveSubtype ) {
		originAccount = true;
		targetAccount = true;
	}

	if ( 'deposit-cash' === moveSubtype ) {
		targetAccount = true;
	}

	if ( 'extract' === moveSubtype ) {
		originAccount = true;
	}

	if ( 'deposit-check' === moveSubtype ) {
		targetAccount = true;
		getThirdCheck = true;
	}

	if ( 'cashing-check' === moveSubtype ) {
		getThirdCheck = true;
	}

	if ( 'other' === moveSubtype ) {
		showPayment = true;
	}


	if (
		null !== currentMove &&
		'third-check-expense' === currentMove.paymentMethod
	) {
		haveThirdChecks = true;
		thirdChecks.push( thirdChecksHistory[ currentMove.thirdCheckPayId ]);
	}

	if (
		null !== currentMove &&
		'kind-expense' === currentMove.paymentMethod
	) {
		haveKinds = true;
		kinds.push( kindsHistory[ currentMove.kindPayId ]);

	}

	if ( '' !== moveSubtype ) {
		loadTemplate(
			tplMovesFormBlock2({
				'meta': {
					'moveSubtypeBeneficiary': moveSubtypeBeneficiary,
					'moveSubtypeProvider': moveSubtypeProvider,
					'moveSubtypePartner': moveSubtypePartner,
					'moveSubtypeDonation': moveSubtypeDonation,
					'originAccount': originAccount,
					'getThirdCheck': getThirdCheck,
				},
				'beneficiaries': beneficiariesActives,
				'partners': partners,
				'bankAccounts': bankAccounts,
				'thirdChecks': thirdChecks,
				move: currentMove,
			}),
			'#move-block2'
		);

		metaTemplateBlock3 = {
			'haveBankAccounts': haveBankAccounts,
			'haveMoreOneBankAccounts': haveMoreOneBankAccounts,
			'moveTypeIncome': moveTypeIncome,
			'moveTypeExpense': moveTypeExpense,
			'moveTypeSwap': moveTypeSwap,
			'targetAccount': targetAccount,
			'haveBankAccountsIssuesOrders': haveBankAccountsIssuesOrders,
			'haveBankAccountsIssuesTransfers': haveBankAccountsIssuesTransfers,
			'haveBankAccountsIssuesChecks': haveBankAccountsIssuesChecks,
			'haveThirdChecks': haveThirdChecks,
			'haveKinds': haveKinds,
			'showPayment': showPayment,
		};

		loadTemplate( tplMovesFormBlock3({
			'meta': metaTemplateBlock3,
			'bankAccounts': bankAccounts,
			move: currentMove,
		}),
		'#move-block3' );
	} else {
		loadTemplate( '', '#move-block2' );
		loadTemplate( '', '#move-block3' );
	}

	loadTemplate( '', '#move-block4' );
}


function changeMovePaymentMethod() {
	var paymentMethod = $( '#payment-method' ).val();
	var cash = false;
	var depositAccount = false;
	var thirdCheckIncome = false;
	var ownCheck = false;
	var kindIncome = false;
	var order = false;
	var transfer = false;
	var thirdCheckExpense = false;
	var kindExpense = false;

	var hideAmount = false;

	if ( 'cash' === paymentMethod ) {
		cash = true;
	}

	if ( 'deposit-account' === paymentMethod ) {
		depositAccount = true;
	}

	if ( 'third-check-income' === paymentMethod ) {
		thirdCheckIncome = true;
	}

	if ( 'kind-income' === paymentMethod ) {
		kindIncome = true;
	}

	if ( 'order' === paymentMethod ) {
		order = true;
	}

	if ( 'transfer' === paymentMethod ) {
		transfer = true;
	}

	if ( 'own-check' === paymentMethod ) {
		ownCheck = true;
	}

	if ( 'third-check-expense' === paymentMethod ) {
		thirdCheckExpense = true;
		hideAmount = true;
	}

	if ( 'kind-expense' === paymentMethod ) {
		kindExpense = true;
		hideAmount = true;
	}

	if ( hideAmount ) {
		$( '#amount' ).parent().parent().addClass( 'd-none' );

	} else {
		$( '#amount' ).parent().parent().removeClass( 'd-none' );
	}

	if ( '' !== paymentMethod ) {
		loadTemplate(
			tplMovesFormBlock4({
				'meta': {
					'cash': cash,
					'depositAccount': depositAccount,
					'thirdCheckIncome': thirdCheckIncome,
					'kindIncome': kindIncome,
					'order': order,
					'transfer': transfer,
					'ownCheck': ownCheck,
					'thirdCheckExpense': thirdCheckExpense,
					'kindExpense': kindExpense,
				},
				'bankAccounts': bankAccounts,
				'bankAccountsIssuesOrders': bankAccountsIssuesOrders,
				'bankAccountsIssuesTransfers': bankAccountsIssuesTransfers,
				'bankAccountsIssuesChecks': bankAccountsIssuesChecks,
				'thirdChecks': thirdChecks,
				'kinds': kinds,
				'move': currentMove,
			}),
			'#move-block4'
		);
	} else {
		loadTemplate( '', '#move-block4' );
	}
}


function save() {
	var newMove;
	var message;
	var data = {};

	var date = $( 'input[name=move-date]' ).val();
	var type = $( 'select[name=move-type]' ).val();
	var amount = $( 'input[name=move-amount]' ).val();
	var note = $( 'input[name=move-note]' ).val();
	var subtype = $( 'select[name=move-subtype]' ).val();
	var beneficiaryId = $( 'select[name=move-beneficiary-id]' ).val();
	var provider = $( 'input[name=move-provider]' ).val();
	var partnerId = $( 'select[name=move-partner-id]' ).val();
	var donor = $( 'input[name=move-donor]' ).val();
	var originAccountId = $( 'select[name=move-origin-account-id]' ).val();
	var thirdCheckId = $( 'select[name=move-third-check-id]' ).val();
	var paymentMethod = $( 'select[name=move-payment-method]' ).val();
	var targetAccountId = $( 'select[name=move-target-account-id]' ).val();
	var depositAccountId = $( 'select[name=move-deposit-account-id]' ).val();
	var thirdCheckBank = $( 'input[name=move-third-check-bank]' ).val();
	var thirdCheckNumber = $( 'input[name=move-third-check-number]' ).val();
	var thirdCheckPayDate = $( 'input[name=move-third-check-pay-date]' ).val();
	var kindDescription = $( 'input[name=move-kind-description]' ).val();
	var orderAccountId = $( 'select[name=move-order-account-id]' ).val();
	var orderNumber = $( 'input[name=move-order-number]' ).val();
	var orderIssuedDate = $( 'input[name=move-order-issued-date]' ).val();
	var transferAccountId = $( 'select[name=move-transfer-account-id]' ).val();
	var thirdCheckPayId = $( 'select[name=move-pay-third-check-id]' ).val();
	var kindPayId = $( 'select[name=move-pay-kind-id]' ).val();
	var ownCheckAccountId = $( 'select[name=move-own-account-id]' ).val();
	var ownCheckNumber = $( 'input[name=move-own-check-number]' ).val();
	var ownCheckPayDate = $( 'input[name=move-own-check-pay-date]' ).val();
	var thirdCheckIdPrev = $( 'input[name=move-third-check-id-prev]' ).val();
	var thirdCheckPayIdPrev = $( 'input[name=move-pay-third-check-id-prev]' ).val();
	var kindPayIdPrev = $( 'input[name=move-pay-kind-id-prev]' ).val();

	alertMsg( '#move-form-response', 'clean' );

	if ( undefined === paymentMethod ) {
		if ( 'deposit-check' === subtype ) {
			paymentMethod = 'deposit-check';
		}
	}

	/**
	 * Validation and set data.
	 */
	if ( '' !== date ) {
		data.date = date;
	} else {
		alertMsg( '#move-form-response', 'danger', 'Debe ingresar una fecha.' );
		return false;
	}

	if ( '' !== type ) {
		data.type = type;

	} else {
		alertMsg( '#move-form-response', 'danger', 'Debe seleccionar un tipo de movimiento.' );
		return false;
	}

	if ( '' !== subtype ) {
		data.subtype = subtype;

	} else {
		alertMsg( '#move-form-response', 'danger', 'Debe seleccionar un subtipo de movimiento.' );
		return false;
	}


	if ( 'beneficiary' === subtype ) {
		if ( '' !== beneficiaryId ) {
			data.beneficiaryId = beneficiaryId;

		} else {
			alertMsg( '#move-form-response', 'danger', 'Debe seleccionar un beneficiario.' );
			return false;
		}
	}

	if ( 'provider' === subtype ) {
		if ( '' !== provider ) {
			data.provider = provider;

		} else {
			alertMsg( '#move-form-response', 'danger', 'Debe ingresar un proveedor.' );
			return false;
		}
	}

	if ( 'partner' === subtype ) {
		if ( '' !== partnerId ) {
			data.partnerId = partnerId;

		} else {
			alertMsg( '#move-form-response', 'danger', 'Debe seleccionar un padrino.' );
			return false;
		}
	}

	if ( 'donation' === subtype ) {
		if ( '' !== donor ) {
			data.donor = donor;

		} else {
			alertMsg( '#move-form-response', 'danger', 'Debe ingresar el donante.' );
			return false;
		}
	}

	if (
		'swap' === subtype ||
		'bank-fees' === subtype ||
		'extract' === subtype
	) {
		if ( '' !== originAccountId ) {
			data.originAccountId = originAccountId;

		} else {
			alertMsg( '#move-form-response', 'danger', 'Debe seleccionar la cuenta de origen.' );
			return false;
		}
	}

	if ( 'deposit-check' === subtype ) {
		if ( '' !== thirdCheckId ) {
			data.thirdCheckId = thirdCheckId;

		} else {
			alertMsg( '#move-form-response', 'danger', 'Debe seleccionar un cheque a depositar.' );
			return false;
		}
	}

	if ( 'cashing-check' === subtype	) {
		if ( '' !== thirdCheckId ) {
			data.thirdCheckId = thirdCheckId;

		} else {
			alertMsg( '#move-form-response', 'danger', 'Debe seleccionar un cheque a cobrar.' );
			return false;
		}
	}


	if (
		'swap' === subtype ||
		'deposit-cash' === subtype ||
		'deposit-check' === subtype
	) {
		if ( '' !== targetAccountId ) {
			data.targetAccountId = targetAccountId;

		} else {
			alertMsg( '#move-form-response', 'danger', 'Debe seleccionar la cuenta de destino.' );
			return false;
		}
	}


	if ( '' !== paymentMethod ) {
		if (
			'swap' !== subtype &&
			'bank-fees' !== subtype &&
			'extract' !== subtype &&
			'deposit-cash' !== subtype
		) {
			data.paymentMethod = paymentMethod;
		}

	} else {
		alertMsg( '#move-form-response', 'danger', 'Debe seleccionar un método de pago.' );
		return false;
	}

	if ( 'deposit-account' === paymentMethod	) {
		if ( '' !== depositAccountId ) {
			data.depositAccountId = depositAccountId;

		} else {
			alertMsg( '#move-form-response', 'danger', 'Debe seleccionar una cuenta de depósito.' );
			return false;
		}
	}

	if ( 'third-check-income' === paymentMethod	) {
		if ( '' === thirdCheckBank ) {
			alertMsg( '#move-form-response', 'danger', 'Debe ingresar el banco emisor del cheque.' );
			return false;
		}

		if ( '' === thirdCheckNumber ) {
			alertMsg( '#move-form-response', 'danger', 'Debe ingresar el número del cheque.' );
			return false;
		}

		if ( '' === thirdCheckPayDate ) {
			message = 'Debe ingresar fecha de vencimiento del cheque.';
			alertMsg( '#move-form-response', 'danger', message );
			return false;
		}

		data.thirdCheck = {};
		data.thirdCheck.bank = thirdCheckBank;
		data.thirdCheck.number = thirdCheckNumber;
		data.thirdCheck.payDate = thirdCheckPayDate;
		data.thirdCheck.status = 1;
	}

	if ( 'kind-income' === paymentMethod	) {
		if ( '' !== kindDescription ) {
			data.kind = {};
			data.kind.description = kindDescription;
			data.kind.status = 1;

		} else {
			message = 'Debe ingresar una descripción para el pago en especie.';

			alertMsg( '#move-form-response', 'danger', message );
			return false;
		}
	}

	if ( 'order' === paymentMethod	) {
		if ( '' === orderAccountId ) {
			alertMsg( '#move-form-response', 'danger', 'Debe seleccionar una cuenta para la orden.' );
			return false;
		}

		if ( '' === orderNumber ) {
			alertMsg( '#move-form-response', 'danger', 'Debe ingresar el número de la orden.' );
			return false;
		}

		if ( '' === orderIssuedDate ) {
			alertMsg( '#move-form-response', 'danger', 'Debe ingresar fecha de emisión de la orden.' );
			return false;
		}

		data.order = {};
		data.order.accountId = orderAccountId;
		data.order.number = orderNumber;
		data.order.issuedDate = orderIssuedDate;
		data.order.status = 1;
	}

	if ( 'transfer' === paymentMethod	) {
		if ( '' !== transferAccountId ) {
			data.transferAccountId = transferAccountId;

		} else {
			message = 'Debe seleccionar una cuenta para la transferencia.';
			alertMsg( '#move-form-response', 'danger', message );
			return false;
		}
	}

	if ( 'third-check-expense' === paymentMethod	) {
		if ( '' !== thirdCheckPayId ) {
			data.thirdCheckPayId = thirdCheckPayId;

		} else {
			alertMsg( '#move-form-response', 'danger', 'Debe seleccionar un cheque para pagar.' );
			return false;
		}
	}

	if ( 'kind-expense' === paymentMethod	) {
		if ( '' !== kindPayId ) {
			data.kindPayId = kindPayId;

		} else {
			alertMsg( '#move-form-response', 'danger', 'Debe seleccionar una especie para pagar.' );
			return false;
		}
	}

	if ( 'own-check' === paymentMethod	) {
		if ( '' === ownCheckAccountId ) {
			alertMsg( '#move-form-response', 'danger', 'Debe seleccionar la cuenta del cheque.' );
			return false;
		}

		if ( '' === ownCheckNumber ) {
			alertMsg( '#move-form-response', 'danger', 'Debe ingresar el número del cheque.' );
			return false;
		}

		if ( '' === ownCheckPayDate ) {
			alertMsg( '#move-form-response', 'danger', 'Debe ingresar fecha de pago del cheque.' );
			return false;
		}

		data.ownCheck = {};
		data.ownCheck.accountId = ownCheckAccountId;
		data.ownCheck.number = ownCheckNumber;
		data.ownCheck.payDate = ownCheckPayDate;
		data.ownCheck.status = 1;
	}

	if ( 0 <= amount ) {
		if ( '' === amount ) {
			data.amount = 0;

		} else {
			data.amount = amount;
		}

	} else {
		alertMsg( '#move-form-response', 'danger', 'El monto no puede ser menor que cero.' );
		return false;
	}

	// No validation.
	if ( '' !== note ) {
		data.note = note;
	}


	/**
	 * Third Cheks
	 */
	// Set to available third check on update.
	if (
		'' !== thirdCheckPayIdPrev &&
		thirdCheckPayIdPrev !== thirdCheckPayId
	) {
		db( '/accounting/' + thirdCheckPayIdPrev + '/thirdCheck/status/' )
			.set( 1 );
	}

	// Set to unavailable third check on set it.
	if ( 'third-check-expense' === paymentMethod ) {
		db( '/accounting/' + thirdCheckPayId + '/thirdCheck/status/' )
			.set( 0 );
	}


	/**
	 * Third Cheks deposit / cashing
	 */
	// Set to available third check on update.
	if (
		'' !== thirdCheckIdPrev &&
		thirdCheckIdPrev !== thirdCheckId
	) {
		db( '/accounting/' + thirdCheckIdPrev + '/thirdCheck/status/' )
			.set( 1 );
	}

	// Set to unavailable third check on set it.
	if ( 'deposit-check' === paymentMethod ) {
		db( '/accounting/' + thirdCheckId + '/thirdCheck/status/' )
			.set( 0 );
	}

	/**
	 * Kinds
	 */
	// Set to available third check on update.
	if (
		'' !== kindPayIdPrev &&
		kindPayIdPrev !== kindPayId
	) {
		db( '/accounting/' + kindPayIdPrev + '/kind/status/' )
			.set( 1 );
	}

	// Set to unavailable third check on set it.
	if ( 'kind-expense' === paymentMethod ) {
		db( '/accounting/' + kindPayId + '/kind/status/' )
			.set( 0 );
	}


	if ( undefined !== id ) {
		newMove = db( '/accounting/' + id + '/' );

	} else {
		newMove = db( '/accounting/' ).push();
	}

	newMove.set( data ).then( function() {
		cancel();
	});
}

function cancel() {
	window.location.hash = '#/accounting/moves/';
}


// End main function.
}
