var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"download-reposrt-links\">\n	<a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"urlWord") || (depth0 != null ? lookupProperty(depth0,"urlWord") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"urlWord","hash":{},"data":data,"loc":{"start":{"line":2,"column":10},"end":{"line":2,"column":21}}}) : helper)))
    + "\" download=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"nameWord") || (depth0 != null ? lookupProperty(depth0,"nameWord") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"nameWord","hash":{},"data":data,"loc":{"start":{"line":2,"column":33},"end":{"line":2,"column":45}}}) : helper)))
    + "\" target=\"_blank\">\n		<span class=\"icon\"><i class=\"fas fa-file-word\"></i></span>\n		<span>Word</span>\n	</a>\n	<a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"urlPdf") || (depth0 != null ? lookupProperty(depth0,"urlPdf") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"urlPdf","hash":{},"data":data,"loc":{"start":{"line":6,"column":10},"end":{"line":6,"column":20}}}) : helper)))
    + "\" download=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"namePdf") || (depth0 != null ? lookupProperty(depth0,"namePdf") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"namePdf","hash":{},"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":43}}}) : helper)))
    + "\" target=\"_blank\">\n		<span class=\"icon\"><i class=\"fas fa-file-pdf\"></i></span>\n		<span>PDF</span>\n	</a>\n</div>";
},"useData":true});