// Sammy
import 'sammy/lib/sammy.js';

// Helpers
import { log } from 'helpers.js';

// Templates
import tplLoading from 'templates/system/loading.html';

// Routes
import routeAccounting from 'routes/accounting.js';
import routeBeneficieries from 'routes/beneficiaries.js';
import routeConfigs from 'routes/configs.js';
import routePartnerReports from 'routes/partnerReports.js';
import routePartners from 'routes/partners.js';
import routeSystem from 'routes/system.js';

// Vars
var route;

// Start Sammy
window.Sammy();
route = window.Sammy.apps.body;

// Default view.
if ( '' === window.location.hash || '#' === window.location.hash ) {
	window.location.hash = '#/';
}

// Default actions.
route.before( function() {
	$( 'main' ).html( tplLoading() );
});

route.after( function() {
	var $menuLink = $( 'a.nav-link[href="' + this.path + '"]' );

	log( this.path );

	if (
		-1 === this.path.indexOf( 'login' ) &&
		-1 === this.path.indexOf( 'logout' )
	) {
		localStorage.setItem( 'last-path', this.path );
	}

	// Highlights the current menu item
	if ( $menuLink.is( 'a' ) ) {
		$( 'a.nav-link' ).removeClass( 'active' );
		$( 'a.nav-link[href="' + this.path + '"]' ).addClass( 'active' );
	}

	// Update history here
});

routeAccounting();
routeBeneficieries();
routeConfigs();
routePartnerReports();
routePartners();
routeSystem();

route.run();
