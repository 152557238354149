// Helpers
import { db, user, loadTemplate, waitingForLoad, st } from 'helpers.js';

// Templates
import tplpartnerReportsDelete from 'templates/partnerReports/delete.html';

export default function viewBeneficiariesView( id ) {
var report;
var reportRef;

if ( ! user.isLogged() ) {
	return false;
}

// On Load data, load Template
waitingForLoad( 'start', 1, function() {
	loadTemplate(
		tplpartnerReportsDelete({ report: report })
	);
});

reportRef = db( '/partnerReports/' + id + '/' );

// Load Template
reportRef
	.once( 'value' )
	.then( function( child ) {
		report = child.val();
		waitingForLoad( 'complete' );
	});


// Init Events
$( 'body' )
	.off( 'click', '.main-cancel' )
	.on( 'click', '.main-cancel', function( e ) {
		e.preventDefault();
		window.location.hash = '#/partner-reports/edit/' + id + '/';
	});

$( 'body' )
	.off( 'click', '.main-delete' )
	.on( 'click', '.main-delete', function( e ) {
		var storageRef;
		var fileWordRef;
		var filePdfRef;

		e.preventDefault();

		// Delete files.
		storageRef = st( 'partnerReports' );

		fileWordRef = storageRef.child( report.fileWordName );
		filePdfRef = storageRef.child( report.filePdfName );

		fileWordRef.delete().then( function() {
			filePdfRef.delete().then( function() {
				reportRef.remove();

				window.location.hash = '#/partner-reports/';
			}).catch( function( error ) {

				// Uh-oh, an error occurred!
			});
		}).catch( function( error ) {

			// Uh-oh, an error occurred!
		});

	});

}
