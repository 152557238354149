var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<tr data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":20,"column":15},"end":{"line":20,"column":22}}}) : helper)))
    + "\">\n			<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":21,"column":7},"end":{"line":21,"column":15}}}) : helper)))
    + "</td>\n			<td></td>\n		</tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom\">\n	<h1 class=\"h2\">Padrinos</h1>\n\n	<div class=\"btn-toolbar mb-2 mb-md-0\">\n		<div class=\"btn-group mr-2\">\n			<button id=\"add-partner\" class=\"btn btn-sm btn-outline-primary\">Agregar</button>\n		</div>\n	</div>\n</div>\n\n<table class=\"table table-hover table-striped\" id=\"partners-list\">\n	<thead class=\"thead-dark\">\n		<tr>\n			<th scope=\"col\">Nombre</th>\n			<th scope=\"col\">&nbsp;</th>\n		</tr>\n	</thead>\n	<tbody>\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"partners") || (depth0 != null ? lookupProperty(depth0,"partners") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"partners","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":2},"end":{"line":24,"column":15}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"partners")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "	</tbody>\n</table>";
},"useData":true});