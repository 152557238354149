import { breadcrumb } from 'helpers.js';

// Views
import viewLogin from 'views/system/login.js';
import viewLoginSuccess from 'views/system/loginSuccess.js';
import viewLogout from 'views/system/logout.js';

import viewDashboard from 'views/system/dashboard.js';
import viewDownloads from 'views/system/downloads.js';

export default function routeSystem() {
	var route = window.Sammy.apps.body;

	// Download
	route.get( '#/download/:file/', function() {
		viewDownloads( route.params.file );
	});


	// Login
	route.get( '#/login/', function() {
		viewLogin();
	});

	route.get( '#/login/success/', function() {
		viewLoginSuccess();
	});

	route.get( '#/logout/', function() {
		viewLogout();
	});


	// Home
	route.get( '#/', function() {
		viewDashboard();
		breadcrumb([]);
	});
}

