// Helpers
import { db, user, loadTemplate, numberWithCommas } from 'helpers.js';
import { dateFormat, waitingForLoad } from 'helpers.js';

// Templates
import tplBenefAccounting from 'templates/beneficiaries/moves.html';

export default function viewBeneficiariesAccounting( id ) {

	/**
	 * Define vars.
	 */
	var beneficiary;
	var moves = [];
	var bankAccounts = {};

	var payMethods = {
		'cash': 'Efectivo',
		'order': 'Orden pago',
		'own-check': 'Cheque Propio',
		'third-check': 'Cheque Tercero',
	};

	/**
	 * If logged out, cancel view.
	 */
	if ( ! user.isLogged() ) {
		return false;
	}

	/**
	 * On Load data, load Template.
	 */
	waitingForLoad( 'start', 3, function() {
		generateView();
	});


	// Load Template
	db( '/beneficiaries/' + id + '/' )
		.once( 'value' )
		.then( function( snapshot ) {
			beneficiary = snapshot.val();

			waitingForLoad( 'complete' );
		});

	db( '/accounting/' )
		.once( 'value' )
		.then( function( snapshot ) {
			var move;

			snapshot.forEach( function( child ) {
				move = child.val();

				if ( id === move.beneficiaryId ) {
					move.key = child.key;

					moves.push( move );
				}
			});

			waitingForLoad( 'complete' );
		});

	db( '/tables/bankAccount/' )
		.once( 'value' )
		.then( function( snapshot ) {
			snapshot.forEach( function( child ) {
				bankAccounts[ child.key ] = child.val();
			});

			waitingForLoad( 'complete' );
		});

	// Init Events
	$( 'body' )
		.off( 'click', '.main-edit' )
		.on( 'click', '.main-edit', function( e ) {
			e.preventDefault();
			window.location.hash = '#/beneficiaries/edit/' + id + '/';
		});

	$( 'body' )
		.off( 'click', '.main-delete' )
		.on( 'click', '.main-delete', function( e ) {
			e.preventDefault();
			window.location.hash = '#/beneficiaries/delete/' + id + '/';
		});

	$( 'body' )
		.off( 'click', '.main-accounting' )
		.on( 'click', '.main-accounting', function( e ) {
			e.preventDefault();
			window.location.hash = '#/beneficiaries/view/' + id + '/accounting/';
		});

	/**
	 * Functions
	 */
	function generateView() {
		var move;
		var i;
		var text;
		var total = 0;

		moves.sort( function( a, b ) {
			var x = a.date.toLowerCase();
			var y = b.date.toLowerCase();

			return x.localeCompare( y );
		});

		moves = moves.reverse();

		for ( i = 0; i < moves.length; i++ ) {
			move = moves[i];

			total = total + parseFloat( move.amount );

			// Format.
			move.date = dateFormat( move.date ) ;
			move.amount = numberWithCommas( move.amount ) ;
			move.paymentMethodText = payMethods[ move.paymentMethod ];

			if ( 'order' == move.paymentMethod ) {
				text = move.paymentMethodText;
				text += ' Nro. ' + move.order.number + ' - ';
				text += bankAccounts[ move.order.accountId ].entity;
				text += ' (';
				text += bankAccounts[ move.order.accountId ].number;
				text += ') ';

				move.paymentMethodText = text;
			}

			moves[i] = move;
		}

		total = numberWithCommas( total );

		loadTemplate(
			tplBenefAccounting({
				moves: moves,
				beneficiary: beneficiary,
				total: total,
			})
		);
	}

}
