import { breadcrumb } from 'helpers.js';

// Views
import viewPartnerReports from 'views/partnerReports/list.js';
import viewPartnerReportsForm from 'views/partnerReports/form.js';
import viewPartnerReportsDelete from 'views/partnerReports/delete.js';

export default function routeSystem() {
	var route = window.Sammy.apps.body;

	// Users
	route.get( '#/partner-reports/', function() {
		viewPartnerReports();
		breadcrumb([ {
			n: 'Informes a padrinos',
		} ]);
	});

	route.get( '#/partner-reports/add/', function() {
		viewPartnerReportsForm();
		breadcrumb([ {
			u: '#/partner-reports/',
			n: 'Informes a padrinos',
		}, {
			n: 'Agregar',
		} ]);
	});

	route.get( '#/partner-reports/edit/:id/', function() {
		viewPartnerReportsForm( this.params.id );
		breadcrumb([ {
			u: '#/partner-reports/',
			n: 'Informes a padrinos',
		}, {
			n: 'Editar',
		} ]);
	});

	route.get( '#/partner-reports/delete/:id/', function() {
		viewPartnerReportsDelete( this.params.id );
		breadcrumb([ {
			u: '#/partner-reports/',
			n: 'Informes a padrinos',
		}, {
			n: 'Eliminar',
		} ]);
	});
}
