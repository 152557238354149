// Helpers
import { db, user, loadTemplate, numberWithCommas } from 'helpers.js';
import { dateFormat, waitingForLoad } from 'helpers.js';

// Templates
import tplAccThirdCheksList from 'templates/accounting/thirdChecksList.html';

// Styles
import 'styles/accounting.css';

export default function viewAccountingValues() {

	/**
	 * Define vars.
	 */
	var partners = {};

	var thirdChecks = [];
	var thirdChecksAvailable = {};
	var thirdChecksHistory = {};

	/**
	 * If logged out, cancel view.
	 */
	if ( ! user.isLogged() ) {
		return false;
	}

	/**
	 * On Load data, load Template.
	 */
	waitingForLoad( 'start', 2, function() {
		generateView();
	});


	/**
	 * Load Data
	 */
	db( '/accounting/' )
		.once( 'value' )
		.then( function( snapshot ) {
			snapshot.forEach( function( child ) {
				var check;

				if ( 'third-check-income' === child.val().paymentMethod ) {
					check = child.val();
					check.key = child.key;

					thirdChecks.push( check );
				}
			});

			waitingForLoad( 'complete' );
		});

	db( '/partners/' )
		.once( 'value' )
		.then( function( snapshot ) {
			snapshot.forEach( function( child ) {
				partners[ child.key ] = {
					'name': child.val().name,
				};
			});

			waitingForLoad( 'complete' );
		});

	/**
	 * Events
	 */
	$( 'body' )
		.off( 'click', '.moves-table tbody tr' )
		.on( 'click', '.moves-table tbody tr', function( e ) {
			e.preventDefault();
			toggleMoveRow( $( e.currentTarget ) );
		});

	/**
	 * Functions
	 */
	function generateView() {
		var i;
		var move;
		var check;
		var temp;

		/**
		 * Prepare Moves
		 */

		// Sort.
		thirdChecks.sort( function( a, b ) {
			var x = a.date.toLowerCase();
			var y = b.date.toLowerCase();

			return x.localeCompare( y );
		});

		thirdChecks = thirdChecks.reverse();

		for ( i = 0; i < thirdChecks.length; i++ ) {
			move = thirdChecks[i];
			check = move.thirdCheck;
			check.key = move.key;

			// Format.
			check.payDate = dateFormat( check.payDate );
			check.amount = numberWithCommas( move.amount );
			check.registerDate = dateFormat( move.date );

			check.details = '<strong>Ingreso:</strong> ';

			if ( 'partner' === move.subtype ) {
				temp = partners[ move.partnerId ].name;

				check.details += 'Donación Padrino: ' + temp;
			}

			if ( 'donation' === move.subtype ) {
				check.details += 'Donación: ' + move.donor;
			}

			if ( 'donation-anonymous' === move.subtype ) {
				check.details += 'Donación Anónima';
			}

			if ( 'other' === move.subtype ) {
				check.details += 'Otros pagos';
			}

			if ( check.status ) {
				thirdChecksAvailable[ check.key ] = check;
			} else {
				thirdChecksHistory[ check.key ] = check;
			}
		}

		loadTemplate( tplAccThirdCheksList({
			thirdChecksAvailable: thirdChecksAvailable,
			thirdChecksHistory: thirdChecksHistory,
		}) );
	}

	function toggleMoveRow( $row ) {
		var $next = $row.next();

		if ( $next.hasClass( 'collapsed' ) ) {
			$next.removeClass( 'collapsed' );

		} else {
			$next.addClass( 'collapsed' );
		}
	}
}
