// Helpers
import { db, user, loadTemplate, waitingForLoad, st } from 'helpers.js';
import { dateFormat } from 'helpers.js';

// Templates
import tplPartnerReports from 'templates/partnerReports/list.html';
import tplDownloadReport from 'templates/partnerReports/downloadReport.html';

// Styles
import 'styles/partnerReport.css';

export default function viewBeneficiaries() {

var reports = [];

if ( ! user.isLogged() ) {
	return false;
}

// On Load data, load Template
waitingForLoad( 'start', 1, function() {
	generateView();
});

db( '/partnerReports/' )
	.once( 'value' )
	.then( function( snapshot ) {
		snapshot.forEach( function( child ) {
			var report;

			report = child.val();
			report.key = child.key;

			reports.push( report );
		});

		waitingForLoad( 'complete' );
	});


/**
 * Events
 */
$( 'body' )
	.off( 'click', '#report-list tbody tr' )
	.on( 'click', '#report-list tbody tr', function( elm ) {
		var id = $( elm.target ).parent( 'tr' ).data( 'id' );
		window.location.hash = '#/partner-reports/edit/' + id + '/';
	});

$( 'body' )
	.off( 'click', '#add-report' )
	.on( 'click', '#add-report', function( e ) {
		e.preventDefault();
		window.location.hash = '#/partner-reports/add/';
	});

$( 'body' )
	.off( 'click', '.download-file' )
	.on( 'click', '.download-file', function( e ) {
		var pathWord;
		var pathPdf;
		var nameWord;
		var namePdf;

		e.preventDefault();
		e.stopPropagation();

		pathWord = $( e.currentTarget ).data( 'path-word' );
		pathPdf = $( e.currentTarget ).data( 'path-pdf' );
		nameWord = $( e.currentTarget ).data( 'name-word' );
		namePdf = $( e.currentTarget ).data( 'name-pdf' );

		$( '#downloadModal' ).modal( 'toggle' );

		st( pathWord ).getDownloadURL().then( function( urlWord ) {
			st( pathPdf ).getDownloadURL().then( function( urlPdf ) {
				loadTemplate( tplDownloadReport({
					urlWord: urlWord,
					urlPdf: urlPdf,
					nameWord: nameWord,
					namePdf: namePdf,
				}), '#modal-body' );

			}).catch( function( error ) {
				// Handle any errors
			});

		}).catch( function( error ) {
			// Handle any errors
		});

	});


/**
 * Functions
 */
function generateView() {
	var i;
	var report;

	reports.sort( function( a, b ) {
		var x = a.date.toLowerCase();
		var y = b.date.toLowerCase();

		return x.localeCompare( y );
	});

	reports = reports.reverse();

	for ( i = 0; i < reports.length; i++ ) {
		report = reports[i];

		// Format.
		report.date = dateFormat( report.date ) ;

		reports[i] = report;
	}

	loadTemplate( tplPartnerReports({
		reports: reports,
	}) );
}


}


