// Helpers
import { db, user, loadTemplate, numberWithCommas } from 'helpers.js';
import { dateFormat, waitingForLoad } from 'helpers.js';

// Templates
import tplBenefAccounting from 'templates/partners/moves.html';

export default function viewPartnersAccounting( id ) {

	/**
	 * Define vars.
	 */
	var partner;
	var moves = [];
	var bankAccounts = {};

	var payMethods = {
		'cash': 'Efectivo',
		'order': 'Orden pago',
		'own-check': 'Cheque Propio',
		'third-check': 'Cheque Tercero',
		'deposit-account': 'Depósito en cuenta',
	};

	/**
	 * If logged out, cancel view.
	 */
	if ( ! user.isLogged() ) {
		return false;
	}

	/**
	 * On Load data, load Template.
	 */
	waitingForLoad( 'start', 3, function() {
		generateView();
	});


	// Load Template
	db( '/partners/' + id + '/' )
		.once( 'value' )
		.then( function( snapshot ) {
			partner = snapshot.val();

			waitingForLoad( 'complete' );
		});

	db( '/accounting/' )
		.once( 'value' )
		.then( function( snapshot ) {
			var move;

			snapshot.forEach( function( child ) {
				move = child.val();

				if ( id === move.partnerId ) {
					move.key = child.key;

					moves.push( move );
				}
			});

			waitingForLoad( 'complete' );
		});

	db( '/tables/bankAccount/' )
		.once( 'value' )
		.then( function( snapshot ) {
			snapshot.forEach( function( child ) {
				bankAccounts[ child.key ] = child.val();
			});

			waitingForLoad( 'complete' );
		});


	/**
	 * Functions
	 */
	function generateView() {
		var move;
		var i;
		var text;
		var total = 0;

		moves.sort( function( a, b ) {
			var x = a.date.toLowerCase();
			var y = b.date.toLowerCase();

			return x.localeCompare( y );
		});

		moves = moves.reverse();

		for ( i = 0; i < moves.length; i++ ) {
			move = moves[i];

			total = total + parseFloat( move.amount );

			// Format.
			move.date = dateFormat( move.date ) ;
			move.amount = numberWithCommas( move.amount ) ;

			if ( 'order' == move.paymentMethod ) {
				text = payMethods[ move.paymentMethod ];
				text += ' Nro. ' + move.order.number + ' - ';
				text += bankAccounts[ move.order.accountId ].entity;
				text += ' (';
				text += bankAccounts[ move.order.accountId ].number;
				text += ') ';

			} else if ( 'deposit-account' == move.paymentMethod ) {
				text = payMethods[ move.paymentMethod ];
				text += ' (';
				text += bankAccounts[ move.depositAccountId ].entity + ' - ';
				text += bankAccounts[ move.depositAccountId ].number;
				text += ') ';

			} else if ( 'third-check-income' == move.paymentMethod ) {
				text = 'Cheque de tercero';
				text += ' - ' + move.thirdCheck.bank;
				text += ' Nro. ' + move.thirdCheck.number;
				text += ' (' + dateFormat( move.thirdCheck.payDate ) + ')';


			} else {
				text = payMethods[ move.paymentMethod ];
			}

			move.paymentMethodText = text;

			moves[i] = move;
		}

		total = numberWithCommas( total );

		loadTemplate(
			tplBenefAccounting({
				moves: moves,
				partner: partner,
				total: total,
			})
		);
	}

}
