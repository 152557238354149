// Import Firebase
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';

import tplMoveFormResp from 'templates/system/alert.html';

export const user = {
	isLogged: function() {
		return firebase.auth().currentUser ? true : false;
	},

	logIn: function( email, pass ) {
		var that = this;

		$( '#form-signin-alert' ).slideUp();
		firebase.auth()
			.setPersistence( firebase.auth.Auth.Persistence.SESSION )
			.then( function() {
				return firebase.auth()
					.signInWithEmailAndPassword( email, pass )
					.catch( function( error ) {

						// Handle Errors here.
						var errorCode = error.code;
						var errorMessage = error.message;
						var i18nMessage = '';

						if ( 'auth/invalid-email' == errorCode ) {
							i18nMessage = 'La dirección de email está mal formateada.';

						} else if ( 'auth/user-not-found' == errorCode ) {
							i18nMessage = 'La dirección de email no existe. El ';
							i18nMessage += 'usuario puede haber sido eliminado.';

						} else if ( 'auth/wrong-password' == errorCode ) {
							i18nMessage = 'La contraseña es incorrecta.';

						} else if ( 'auth/user-disabled' == errorCode ) {
							i18nMessage = 'La cuenta de usuario ha sido ';
							i18nMessage += 'deshabilitada por un administrador.';

						}

						$( '#form-signin-alert' ).text( i18nMessage ).slideDown();

						that.log( errorCode + ', ' + errorMessage );
					});
			})
			.catch( function( error ) {

				// Handle Errors here.
				var errorCode = error.code;
				var errorMessage = error.message;

				that.log( errorCode + ', ' + errorMessage );
			});
	},

	logOut: function() {
		firebase.auth().signOut();
	},

	endIfLogout: function() {
		if ( ! user.isLogged() ) {
			return false;
		}
	},
};

export const dynamicTable = function( id ) {

	var saveForm = function( $button, dT ) {
		var values = {};
		var valida = true;

		var $inputs = $button.parents( 'tr' ).find( 'td [name]' );
		var tableId = $button.parents( 'table' ).attr( 'id' );

		// Check for input validations.
		$inputs.each( function( idx, elm ) {
			var value = $( elm ).val();
			var validate = $( elm ).data( 'validate' );
			var type = $( elm ).attr( 'type' );
			var name = $( elm ).attr( 'name' );

			if ( 'req' == validate && '' == value ) {
				valida = false;
				return false;
			}

			if ( 'file' == type ) {
				return true;
			}

			values[ name ] = value;
		});

		// If pass validations, add row.
		if ( valida ) {
			dT.addRow( values );

			$inputs.each( function( idx, elm ) {
				var dataDefault = $( elm ).data( 'default' );

				if ( undefined !== dataDefault ) {
					$( elm ).val( dataDefault );

				} else {
					$( elm ).val( '' );
				}
			});
		}

		if ( 'table-attachment' === tableId ) {
			$button.prop( 'disabled', true );
		}
	};

	this.tableId = id;

	this.init = function() {
		this.events();

		return this;
	};

	this.events = function() {
		var that = this;

		$( 'body' ).on(
			'click',
			'#' + this.tableId + ' tbody .delete',
			function( e ) {
				e.preventDefault();
				that.deleteRow( $( e.target ).parents( 'tr' ) );
			});

		$( 'body' ).on(
			'click',
			'#' + this.tableId + ' tfoot .add',
			function( e ) {
				e.preventDefault();
				saveForm( $( e.target ), that );
			});
	};

	this.deleteRow = function( $row ) {
		$row.remove();
	};

	this.addRow = function( values ) {
		var $tr = $( '<tr data-id="#"></tr>' );
		var html = '';

		$.each( values, function( key, val ) {
			html = '<td data-key="' + key + '" data-value="' + val + '">';
			html += val + '</td>';

			$tr.append( html );
		});

		html = '<td>';
		html += '<button type="button" class="btn btn-danger btn-sm delete">';
		html += '<i class="fas fa-trash-alt"></i>';
		html += '</button>';
		html += '</td>';

		$tr.append( html );

		$( '#' + this.tableId ).append( $tr );
	};

	this.getValues = function() {
		var values = {};

		var $row = $( '#' + this.tableId + ' tbody tr' );

		$row.each( function( idx, elm ) {
			var id = $( elm ).data( 'id' );
			var val = {};

			var $cell = $( elm ).find( 'td' );

			if ( '#' === id ) {
				id = generateHash();
			}

			$cell.each( function( idx2, elm2 ) {
				var key = $( elm2 ).data( 'key' );
				var value = $( elm2 ).data( 'value' );

				if ( undefined !== key ) {
					val[ key ] = value;
				}
			});

			values[id] = val;
		});

		return values;
	};
};

export const loadTemplate = function( html, elm ) {
	var targetElement = 'main';

	if ( undefined !== elm ) {
		targetElement = elm;
	}

	$( targetElement ).html( html );

	// Set select option
	$( targetElement + ' select' ).each( function( idx, elm ) {
		var value = $( elm ).attr( 'value' );

		if ( undefined !== value ) {
			$( elm ).val( value );
		}

		setTimeout( function() {
			$( elm ).trigger( 'change' );
		}, 100 );
	});
};

export const files = function( id, base ) {
	this.fileInputId = id;
	this.basePath = base;

	this.init = function() {
		this.events();
	};

	this.events = function() {
		var that = this;

		$( 'body' ).on( 'change', '#' + this.fileInputId, function( e ) {
			var $inputFile;
			var wait;

			e.preventDefault();

			$inputFile = $( e.currentTarget );
			$inputFile.prop( 'disabled', true );

			clearTimeout( wait );

			wait = setTimeout( function() {
				that.attach( $inputFile );
			}, 1000 );
		});
	};

	this.attach = function( $inputFile ) {
		var that = this;

		const file = $( '#' + this.fileInputId ).get( 0 ).files[0];

		const ref = firebase.storage().ref( this.basePath + '/' );
		const name = generateHash();
		const metadata = { contentType: file.type };
		const task = ref.child( name ).put( file, metadata );


		task.then( function( snapshot ) {
			$( '#' + that.fileInputId ).next().val(
				encodeURIComponent( snapshot.metadata.fullPath )
			);

			$( '#' + that.fileInputId ).val( '' );

			$inputFile
				.prop( 'disabled', false )
				.parents( 'tr' ).find( '.add' )
				.prop( 'disabled', false );

		}).catch( ( error ) => {
			that.log( error, 'error' );
			$inputFile.prop( 'disabled', false );
		});
	};
};


export const generateHash = function() {
	var i;
	var text = '';
	var possible = '';

	possible += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	possible += 'abcdefghijklmnopqrstuvwxyz';
	possible += '0123456789-';

	for ( i = 0; 20 > i; i++ ) {
		text += possible.charAt(
			Math.floor( Math.random() * possible.length )
		);
	}

	return text;
};

export const breadcrumb = function( items ) {
	var i;
	var html;
	items.unshift({
		u: '/#/',
		n: 'Inicio',
	});

	$( '#breadcrumb' ).html( '' );

	for ( i = 0; i < items.length; i++ ) {
		if ( i != items.length - 1 ) {
			html = '<li class="breadcrumb-item">';
			html += '<a href="' + items[i].u + '">' + items[i].n + '</a>';
			html += '</li>';

		} else {
			html = '<li class="breadcrumb-item active">';
			html += items[i].n;
			html += '</li>';
		}

		$( '#breadcrumb' ).append( html );
	}
};

export const db = function( ref ) {
	if ( 'localhost' === window.location.hostname ) {
		ref = '/dev' + ref;

	} else {
		ref = '/prod' + ref;

	}

	return firebase.database().ref( ref );
};

export const st = function( ref ) {
	return firebase.storage().ref( ref );
};

export const log = function( text, level = 'log' ) {
	if ( 'localhost' !== window.location.hostname ) {
		return true;
	}

	if ( 'log' === level ) {
		console.log( text );

	} else if ( 'error' === level ) {
		console.error( text );
	}
};

export const numberWithCommas = function( x ) {
	if ( undefined === x ) {
		return x;
	}

	var num = ( Math.round( x * 100 ) / 100 ).toFixed( 2 );

	var parts = num.toString().split( '.' );

	parts[0] = parts[0].replace( /\B(?=(\d{3})+(?!\d))/g, '.' );

	return parts.join( ',' );
};


export const dateFormat = function( x ) {
	if ( undefined === x ) {
		return x;
	}

	var date = x.split( '-' );

	return date[2] + '/' + date[1] + '/' + date[0];
};

export const dniFormat = function( x ) {
	if ( undefined === x ) {
		return x;
	}

	return x.replace( /\B(?=(\d{3})+(?!\d))/g, '.' );
};

export const waitingForLoad = function( status, count, cb ) {
	if ( 'start' === status ) {
		window.waitingForLoad = {};

		window.waitingForLoad.count = parseInt( count );
		window.waitingForLoad.cb = cb;
	}

	if ( 'complete' === status ) {
		window.waitingForLoad.count = window.waitingForLoad.count - 1;

		waitingForLoad( 'check' );
	}

	if ( 'check' === status ) {
		if ( 0 === window.waitingForLoad.count ) {
			waitingForLoad( 'end' );
		}
	}

	if ( 'end' === status ) {
		window.waitingForLoad.cb();
		delete window.waitingForLoad;
	}
};


export const alertMsg = function( id, type, message ) {
	if ( 'clean' === type ) {
		$( id ).html( '' );

	} else {
		if ( undefined === type ) {
			type = 'danger';
		}

		if ( undefined === message ) {
			message = 'Error al procesar el formulario';
		}

		loadTemplate( tplMoveFormResp({
			type: type,
			message: message,
		}), id );
	}
}