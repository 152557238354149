// Helpers
import { db, user, loadTemplate } from 'helpers.js';

// Templates
import tplBeneficiariesDelete from 'templates/beneficiaries/delete.html';

export default function viewBeneficiariesView( id ) {
	var beneficiary;

	if ( ! user.isLogged() ) {
		return false;
	}

	// Get Beneficiary instance
	beneficiary = db( '/beneficiaries/' + id + '/' );

	// Load Template
	beneficiary.once( 'value' )
		.then( function( snapshot ) {
			loadTemplate( tplBeneficiariesDelete({
				beneficiary: snapshot.val(),
			}) );
		});

	// Init Events
	$( 'body' )
		.off( 'click', '.main-cancel' )
		.on( 'click', '.main-cancel', function( e ) {
			e.preventDefault();
			window.location.hash = '#/beneficiaries/view/' + id + '/';
		});

	$( 'body' )
		.off( 'click', '.main-delete' )
		.on( 'click', '.main-delete', function( e ) {
			e.preventDefault();
			beneficiary.remove();
			window.location.hash = '#/beneficiaries/';
		});
}
