var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom\">\n	<h1 class=\"h2\">Ficha de Padrino: "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"partner") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h1>\n\n	<div class=\"mr-2\">\n		<button class=\"btn btn-sm btn-outline-success main-accounting\">Contabilidad</button>\n		<button class=\"btn btn-sm btn-outline-primary main-edit\">Editar</button>\n		<button class=\"btn btn-sm btn-outline-danger main-delete\">Borrar</button>\n	</div>\n</div>\n\n<div class=\"row ficha-partner\">\n<div class=\"col-md-6\">\n	\n	<h4>General</h4>\n	<ul>\n		<li><strong>Nombre o Razón Social:</strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"partner") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</li>\n		<li><strong>Teléfono:</strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"partner") : depth0)) != null ? lookupProperty(stack1,"tel") : stack1), depth0))
    + "</li>\n		<li><strong>Email:</strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"partner") : depth0)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + "</li>\n		<li><strong>Dirección:</strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"partner") : depth0)) != null ? lookupProperty(stack1,"address") : stack1), depth0))
    + "</li>\n	</ul>\n\n	<h4>Donación</h4>\n	<ul>\n		<li><strong>Forma Habitual:</strong> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"partner") : depth0)) != null ? lookupProperty(stack1,"donate") : stack1)) != null ? lookupProperty(stack1,"method") : stack1), depth0))
    + "</li>\n		<li><strong>DNI / CUIT:</strong> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"partner") : depth0)) != null ? lookupProperty(stack1,"donate") : stack1)) != null ? lookupProperty(stack1,"cuit") : stack1), depth0))
    + "</li>\n		<li><strong>IVA:</strong> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"partner") : depth0)) != null ? lookupProperty(stack1,"donate") : stack1)) != null ? lookupProperty(stack1,"iva") : stack1), depth0))
    + "</li>\n	</ul>\n</div>\n</div>";
},"useData":true});