import { breadcrumb } from 'helpers.js';

// Views
import viewUsers from 'views/configs/users.js';

export default function routeSystem() {
	var route = window.Sammy.apps.body;

	// Users
	route.get( '#/users/', function() {
		viewUsers();
		breadcrumb([ { n: 'Usuarios' } ]);
	});
}
