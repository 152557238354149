// Helpers
import { db, user, loadTemplate, numberWithCommas } from 'helpers.js';
import { dateFormat, waitingForLoad } from 'helpers.js';

// Templates
import tplAccountingOrders from 'templates/accounting/ordersList.html';

// Styles
import 'styles/accounting.css';

export default function viewAccountingValues() {

	/**
	 * Define vars.
	 */
	var bankAccounts = {};
	var beneficiaries = {};

	var orders = [];
	var ordersIssued = {};
	var ordersPaid = {};
	var haveIssuesOrders = false;

	/**
	 * If logged out, cancel view.
	 */
	if ( ! user.isLogged() ) {
		return false;
	}

	/**
	 * On Load data, load Template.
	 */
	waitingForLoad( 'start', 3, function() {
		generateView();
	});


	/**
	 * Load Data
	 */
	db( '/accounting/' )
		.once( 'value' )
		.then( function( snapshot ) {
			snapshot.forEach( function( child ) {
				var order;

				if ( 'order' === child.val().paymentMethod ) {
					order = child.val();
					order.key = child.key;

					orders.push( order );
				}

			});

			if ( 1 <= orders.length ) {
				haveIssuesOrders = true;
			}

			waitingForLoad( 'complete' );
		});

	db( '/tables/bankAccount/' )
		.once( 'value' )
		.then( function( snapshot ) {
			var account = null;

			snapshot.forEach( function( child ) {
				account = child.val();
				account.key = child.key;

				bankAccounts[ account.key ] = account;
			});

			waitingForLoad( 'complete' );
		});

	db( '/beneficiaries/' )
		.once( 'value' )
		.then( function( snapshot ) {
			snapshot.forEach( function( child ) {
				beneficiaries[ child.key ] = {
					'surname': child.val().surname,
					'name': child.val().name,
				};
			});

			waitingForLoad( 'complete' );
		});

	/**
	 * Events
	 */
	$( 'body' )
		.off( 'click', '.moves-table tbody tr' )
		.on( 'click', '.moves-table tbody tr', function( e ) {
			e.preventDefault();
			toggleMoveRow( $( e.currentTarget ) );
		});

	/**
	 * Functions
	 */
	function generateView() {
		var i;
		var move;
		var order;
		var temp;
		var text;

		/**
		 * Prepare Moves
		 */

		// Sort.
		orders.sort( function( a, b ) {
			var x = a.date.toLowerCase();
			var y = b.date.toLowerCase();

			return x.localeCompare( y );
		});

		orders = orders.reverse();

		for ( i = 0; i < orders.length; i++ ) {
			move = orders[i];
			order = move.order;
			order.key = move.key;

			// Format.
			order.amount = numberWithCommas( move.amount ) ;
			order.registerDate = dateFormat( move.date );
			order.issuedDate = dateFormat( order.issuedDate ) ;
			order.paymentDate = dateFormat( order.paymentDate );

			order.account = bankAccounts[ order.accountId ];
			order.accountText = order.account.entity ;
			order.accountText += ' (' + order.account.number + ')';

			if ( 'beneficiary' === move.subtype ) {
				temp = beneficiaries[ move.beneficiaryId ];
				text = temp.surname + ', ' + temp.name;

				order.details = 'Pago Beneficiario: ' + text;
			}

			if ( 'provider' === move.subtype ) {
				order.details = 'Pago Proveedor: ' + text;
			}

			if ( 'other' === move.subtype ) {
				order.details = 'Otros pagos';
			}


			if ( order.status ) {
				ordersIssued[ order.key ] = order;
			} else {
				ordersPaid[ order.key ] = order;
			}
		}

		loadTemplate( tplAccountingOrders({
			ordersIssued: ordersIssued,
			ordersPaid: ordersPaid,
			haveIssuesOrders: haveIssuesOrders,
		}) );
	}

	function toggleMoveRow( $row ) {
		var $next = $row.next();

		if ( $next.hasClass( 'collapsed' ) ) {
			$next.removeClass( 'collapsed' );

		} else {
			$next.addClass( 'collapsed' );
		}
	}
}
