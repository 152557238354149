// Helpers
import { db, user, loadTemplate } from 'helpers.js';

// Templates
import tplPartnersDelete from 'templates/partners/delete.html';

export default function viewBeneficiariesView( id ) {
	var partner;

	if ( ! user.isLogged() ) {
		return false;
	}

	// Get Beneficiary instance
	partner = db( '/partners/' + id + '/' );

	// Load Template
	partner.once( 'value' )
		.then( function( snapshot ) {
			loadTemplate( tplPartnersDelete({ partner: snapshot.val() }) );
		});

	// Init Events
	$( 'body' )
		.off( 'click', '.main-cancel' )
		.on( 'click', '.main-cancel', function( e ) {
			e.preventDefault();
			window.location.hash = '#/partners/view/' + id + '/';
		});

	$( 'body' )
		.off( 'click', '.main-delete' )
		.on( 'click', '.main-delete', function( e ) {
			e.preventDefault();
			partner.remove();
			window.location.hash = '#/partners/';
		});
}
