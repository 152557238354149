// Helpers
import { db, user, loadTemplate } from 'helpers.js';

// Templates
import tplPartnersForm from 'templates/partners/form.html';

export default function viewPartnersForm( id ) {
	if ( ! user.isLogged() ) {
		return false;
	}

	// Load Template
	db( '/partners/' + id + '/' )
		.once( 'value' )
		.then( function( snapshot ) {
			loadTemplate( tplPartnersForm({ partner: snapshot.val() }) );
		});

	// Init Events
	$( 'body' )
		.off( 'click', '.main-save' )
		.on( 'click', '.main-save', function( e ) {
			e.preventDefault();
			save();
		});

	$( 'body' )
		.off( 'click', '.main-cancel' )
		.on( 'click', '.main-cancel', function( e ) {
			e.preventDefault();
			cancel();
		});

	// Functions
	function save() {
		var newPartner;
		var key;

		var data = {
			'name': $( 'input[name=name]' ).val(),
			'tel': $( 'input[name=tel]' ).val(),
			'email': $( 'input[name=email]' ).val(),
			'address': $( 'input[name=address]' ).val(),
			'donate': {
				'method': $( 'select[name=donateMethod]' ).val(),
				'cuit': $( 'input[name=cuit]' ).val(),
				'iva': $( 'select[name=iva]' ).val(),
			},
		};

		if ( undefined !== id ) {
			newPartner = db( '/partners/' + id + '/' );

		} else {
			newPartner = db( '/partners/' ).push();

		}

		key = newPartner.key;

		newPartner.set( data ).then( function() {
			window.location.hash = '#/partners/view/' + key + '/';
		});
	}

	function cancel() {
		if ( undefined !== id ) {
			window.location.hash = '#/partners/view/' + id + '/';

		} else {
			window.location.hash = '#/partners/';
		}
	}
}
