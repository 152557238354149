// Import Firebase
import firebase from 'firebase/app';
import 'firebase/auth';

// Helpers
import { log } from './helpers.js';

firebase.auth().onAuthStateChanged( function( user ) {
	if ( user ) {
		log( 'in' );
		window.location.hash = '#/login/success/';

	} else {
		log( 'out' );
		window.location.hash = '#/login/';
	}
});
