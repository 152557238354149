var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<tr>\n						<td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"relation") : depth0), depth0))
    + "</td>\n						<td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</td>\n					</tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<tr>\n						<td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "</td>\n						<td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"street1") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"street2") : depth0), depth0))
    + ", ("
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"zipcode") : depth0), depth0))
    + ") "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"city") : depth0), depth0))
    + ", "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"state") : depth0), depth0))
    + ", "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"country") : depth0), depth0))
    + "</td>\n					</tr>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<tr>\n						<td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "</td>\n						<td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "</td>\n					</tr>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<li>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " <a href=\"/#/download/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"file") : depth0), depth0))
    + "/\" target=\"_blank\"><i class=\"fas fa-paperclip\"></i></a></li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom\">\n	<h1 class=\"h2\">Ficha de Beneficiario: "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"surname") : stack1), depth0))
    + ", "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h1>\n\n	<div class=\"mr-2\">\n		<button class=\"btn btn-sm btn-outline-success main-accounting\">Contabilidad</button>\n		<button class=\"btn btn-sm btn-outline-primary main-edit\">Editar</button>\n		<button class=\"btn btn-sm btn-outline-danger main-delete\">Borrar</button>\n	</div>\n</div>\n\n<div class=\"row ficha-beneficiario\">\n<div class=\"col-lg-6\">\n	<div class=\"card mb-3\">\n		<div class=\"card-body\">\n			<h5 class=\"card-title\">General</h5>\n			<ul>\n				<li><strong>Apellidos:</strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"surname") : stack1), depth0))
    + "</li>\n				<li><strong>Nombres:</strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</li>\n				<li><strong>DNI:</strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"dni") : stack1), depth0))
    + "</li>\n				<li><strong>CUIL:</strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"cuil") : stack1), depth0))
    + "</li>\n				<li><strong>Fecha de Nacimiento:</strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"birthdate") : stack1), depth0))
    + "</li>\n				<li><strong>Estado Civil:</strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"civilStatus") : stack1), depth0))
    + "</li>\n				<li><strong>Ocupación:</strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"job") : stack1), depth0))
    + "</li>\n				<li><strong>Beneficio Social:</strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"social") : stack1), depth0))
    + "</li>\n				<li><strong>Vivienda:</strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"home") : stack1), depth0))
    + "</li>\n			</ul>\n		</div>\n	</div>\n\n	<div class=\"card mb-3\">\n		<div class=\"card-body\">\n			<h5 class=\"card-title\">Carrera</h5>\n			<ul>\n				<li><strong>Carrera:</strong> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"career") : stack1)) != null ? lookupProperty(stack1,"career") : stack1), depth0))
    + "</li>\n				<li><strong>Título:</strong> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"career") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</li>\n				<li><strong>Establecimiento:</strong> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"career") : stack1)) != null ? lookupProperty(stack1,"establishment") : stack1), depth0))
    + "</li>\n				<li><strong>Objetivos personales:</strong> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"career") : stack1)) != null ? lookupProperty(stack1,"objectives") : stack1), depth0))
    + "</li>\n			</ul>\n		</div>\n	</div>\n\n	<div class=\"card mb-3\">\n		<div class=\"card-body\">\n			<h5 class=\"card-title\">Estudios Secundarios</h5>\n			<ul>\n				<li><strong>Establecimiento:</strong> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"studies") : stack1)) != null ? lookupProperty(stack1,"establishment") : stack1), depth0))
    + "</li>\n				<li><strong>Título:</strong> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"studies") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</li>\n				<li><strong>Materias pendientes:</strong> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"studies") : stack1)) != null ? lookupProperty(stack1,"penndings") : stack1), depth0))
    + "</li>\n			</ul>\n		</div>\n	</div>\n\n	<div class=\"card mb-3\">\n		<div class=\"card-body\">\n			<h5 class=\"card-title\">Observaciones</h5>\n			<p>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"observations") : stack1), depth0))
    + "</p>\n		</div>\n	</div>\n\n</div>\n<div class=\"col-md-6\">\n\n	<div class=\"card mb-3\">\n		<div class=\"card-body\">\n			<h5 class=\"card-title\">Beneficio</h5>\n			<ul>\n				<li><strong>Fecha de Alta:</strong> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"benefit") : stack1)) != null ? lookupProperty(stack1,"dateStart") : stack1), depth0))
    + "</li>\n				<li><strong>Fecha de Fin:</strong> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"benefit") : stack1)) != null ? lookupProperty(stack1,"dateEnd") : stack1), depth0))
    + "</li>\n				<li><strong>Estado:</strong> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"benefit") : stack1)) != null ? lookupProperty(stack1,"status") : stack1), depth0))
    + "</li>\n			</ul>\n		</div>\n	</div>\n\n	<div class=\"card mb-3\">\n		<div class=\"card-body\">\n			<h5 class=\"card-title\">Familia</h5>\n\n			<table class=\"table table-striped table-sm\">\n				<thead>\n					<tr>\n						<th scope=\"col\">Parentesco</th>\n						<th scope=\"col\">Nombre</th>\n					</tr>\n				</thead>\n				<tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"family") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":5},"end":{"line":91,"column":14}}})) != null ? stack1 : "")
    + "				</tbody>\n			</table>\n		</div>\n	</div>\n\n	<div class=\"card mb-3\">\n		<div class=\"card-body\">\n			<h5 class=\"card-title\">Contacto</h5>\n\n			<h6>Direcciones</h6>\n			<table class=\"table table-striped table-sm\">\n				<thead>\n					<tr>\n						<th scope=\"col\">Tipo</th>\n						<th scope=\"col\">Dirección</th>\n					</tr>\n				</thead>\n				<tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"contact") : stack1)) != null ? lookupProperty(stack1,"addresses") : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":5},"end":{"line":115,"column":14}}})) != null ? stack1 : "")
    + "				</tbody>\n			</table>\n\n			<h6>Emails</h6>\n			<table class=\"table table-striped table-sm\">\n				<thead>\n					<tr>\n						<th scope=\"col\">Tipo</th>\n						<th scope=\"col\">Email</th>\n					</tr>\n				</thead>\n				<tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"contact") : stack1)) != null ? lookupProperty(stack1,"emails") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":128,"column":5},"end":{"line":133,"column":14}}})) != null ? stack1 : "")
    + "				</tbody>\n			</table>\n\n			<h6>Teléfonos</h6>\n			<table class=\"table table-striped table-sm\">\n				<thead>\n					<tr>\n						<th scope=\"col\">Tipo</th>\n						<th scope=\"col\">Teléfono</th>\n					</tr>\n				</thead>\n				<tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"contact") : stack1)) != null ? lookupProperty(stack1,"phones") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":146,"column":5},"end":{"line":151,"column":14}}})) != null ? stack1 : "")
    + "				</tbody>\n			</table>\n		</div>\n	</div>\n\n	<div class=\"card mb-3\">\n		<div class=\"card-body\">\n			<h5 class=\"card-title\">Adjuntos</h5>\n\n			<ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0)) != null ? lookupProperty(stack1,"files") : stack1),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":162,"column":4},"end":{"line":164,"column":13}}})) != null ? stack1 : "")
    + "			</ul>\n		</div>\n	</div>\n</div>\n\n</div>";
},"useData":true});