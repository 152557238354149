var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"subtitle") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":19,"column":7},"end":{"line":33,"column":14}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							</ul>\n\n							<h6 class=\"sidebar-heading w-100 d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted\">\n								<span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":23,"column":14},"end":{"line":23,"column":22}}}) : helper)))
    + "</span>\n							</h6>\n\n							<ul class=\"nav w-100 flex-column\">\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<li class=\"nav-item\">\n								<a class=\"nav-link\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":29,"column":34},"end":{"line":29,"column":41}}}) : helper)))
    + "\">\n									<i class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ico") || (depth0 != null ? lookupProperty(depth0,"ico") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ico","hash":{},"data":data,"loc":{"start":{"line":30,"column":19},"end":{"line":30,"column":26}}}) : helper)))
    + "\"></i> "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":30,"column":33},"end":{"line":30,"column":41}}}) : helper)))
    + "\n								</a>\n							</li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "	 <nav class=\"navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0\">\n			<a class=\"navbar-brand col-sm-3 col-md-2 mr-0\" href=\"#/\">Atenea</a>\n			\n			<ol class=\"breadcrumb w-100\" id=\"breadcrumb\"> </ol>\n			\n			<ul class=\"navbar-nav px-3\">\n				<li class=\"nav-item text-nowrap\">\n					<a class=\"nav-link\" href=\"#/logout/\">Salir</a>\n				</li>\n			</ul> \n		</nav>\n\n		<div class=\"container-fluid\">\n			<div class=\"row\">\n				<nav class=\"col-md-2 d-flex align-items-end flex-column sidebar\">\n					<ul class=\"nav w-100 flex-column\">\n\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"menu") || (depth0 != null ? lookupProperty(depth0,"menu") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"menu","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":6},"end":{"line":34,"column":15}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"menu")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n					</ul>\n	\n					<footer class=\"w-100 p-1 mt-auto text-center small\">Desarrollado por <a href=\"https://panuweb.com/\">Mauricio Panuncio</a> - v0.8.0</footer>\n				</nav>\n\n				<main role=\"main\" class=\"col-md-9 ml-sm-auto col-lg-10 pt-3 px-4\">\n\n				</main>\n			</div>\n		</div>";
},"useData":true});