// Helpers
import { db, user, loadTemplate, dniFormat } from 'helpers.js';

// Templates
import tplBeneficiaries from 'templates/beneficiaries/list.html';

// Styles
import 'styles/beneficiaries.css';

export default function viewBeneficiaries() {
	if ( ! user.isLogged() ) {
		return false;
	}

	db( '/beneficiaries/' )
		.once( 'value' )
		.then( function( snapshot ) {

			var beneficiaries = [];
			var beneficiariesActives = [];
			var beneficiariesOthers = [];
			var i;

			snapshot.forEach( function( child ) {
				beneficiaries.push({
					'key': child.key,
					'surname': child.val().surname,
					'name': child.val().name,
					'dni': child.val().dni,
					'career': child.val().career.career,
					'benefitStatus': child.val().benefit.status,
				});
			});

			beneficiaries.sort( function( a, b ) {
				var x = a.surname.toLowerCase();
				var y = b.surname.toLowerCase();
				var z = a.name.toLowerCase();
				var w = b.name.toLowerCase();

				if ( x === y ) {
					return z.localeCompare( w );
				}

				return x.localeCompare( y );
			});

			// format
			beneficiaries.forEach( function( elm, idx ) {
				beneficiaries[ idx ].dni = dniFormat( beneficiaries[ idx ].dni );
			});

			beneficiaries.forEach( function( elm, idx ) {
				if ( 'Activo' === elm.benefitStatus ) {
					beneficiariesActives.push( elm);

				} else {
					beneficiariesOthers.push( elm);
				}
			});

			loadTemplate( tplBeneficiaries({
				beneficiariesActives: beneficiariesActives,
				beneficiariesOthers: beneficiariesOthers,
			}) );
		});


	$( 'body' )
		.off( 'click', '#beneficiaries-list tbody tr' )
		.on( 'click', '#beneficiaries-list tbody tr', function( elm ) {
			var id = $( elm.target ).parent( 'tr' ).data( 'id' );
			window.location.hash = '#/beneficiaries/view/' + id + '/';
		});

	$( 'body' )
		.off( 'click', '#add-beneficiary' )
		.on( 'click', '#add-beneficiary', function( e ) {
			e.preventDefault();
			window.location.hash = '#/beneficiaries/add/';
		});
}
