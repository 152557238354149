// Helpers
import { db, user, loadTemplate } from 'helpers.js';

// Templates
import tplDashboard from 'templates/system/dashboard.html';

export default function viewIndex() {
	if ( ! user.isLogged() ) {
		return false;
	}

	db( '/beneficiaries/' )
		.once( 'value' )
		.then( function( snapshot ) {
			var totalBeneficiaries = 0;
			var activeBeneficiaries = 0;

			snapshot.forEach( function( child ) {
				totalBeneficiaries += 1;

				if ( 'Activo' === child.val().benefit.status ) {
					activeBeneficiaries += 1;
				}
			});

			db( '/partners/' )
				.once( 'value' )
				.then( function( snapshot ) {
					var partners = 0;

					snapshot.forEach( function( child ) {
						partners += 1;
					});

					loadTemplate( tplDashboard({
						totalBeneficiaries: totalBeneficiaries,
						activeBeneficiaries: activeBeneficiaries,
						partners: partners,
					}) );
				});
	});
}
