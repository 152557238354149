import { breadcrumb } from 'helpers.js';

// Views
import viewPartners from 'views/partners/list.js';
import viewPartnersDelete from 'views/partners/delete.js';
import viewPartnersFile from 'views/partners/file.js';
import viewPartnersForm from 'views/partners/form.js';
import viewPartnersAccounting from 'views/partners/moves.js';

export default function routeSystem() {
	var route = window.Sammy.apps.body;

	route.get( '#/partners/', function() {
		viewPartners();
		breadcrumb([ { n: 'Padrinos' } ]);
	});

	route.get( '#/partners/add/', function() {
		viewPartnersForm();
		breadcrumb([ {
			u: '#/partners/',
			n: 'Padrinos',
		}, {
			n: 'Agregar',
		} ]);
	});

	route.get( '#/partners/view/:id/', function() {
		viewPartnersFile( this.params.id );
		breadcrumb([ {
			u: '#/partners/',
			n: 'Padrinos',
		}, {
			n: 'Ficha',
		} ]);
	});

	route.get( '#/partners/edit/:id/', function() {
		viewPartnersForm( this.params.id );
		breadcrumb([ {
			u: '#/partners/',
			n: 'Padrinos',
		}, {
			n: 'Editar',
		} ]);
	});

	route.get( '#/partners/delete/:id/', function() {
		viewPartnersDelete( this.params.id );
		breadcrumb([ {
			u: '#/partners/',
			n: 'Padrinos',
		}, {
			n: 'Eliminar',
		} ]);
	});

	route.get( '#/partners/view/:id/accounting/', function() {
		viewPartnersAccounting( this.params.id );
		breadcrumb([ {
			u: '#/partners/',
			n: 'Padrinos',
		}, {
			u: '#/partners/view/' + this.params.id + '/',
			n: 'Ficha',
		}, {
			n: 'Contabilidad',
		} ]);
	});
}
