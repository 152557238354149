// Helpers
import { db, user, loadTemplate } from 'helpers.js';

// Templetes
import tplPartnersFile from 'templates/partners/file.html';

export default function viewPartnersView( id ) {
	if ( ! user.isLogged() ) {
		return false;
	}

	// Load Template
	db( '/partners/' + id + '/' )
		.once( 'value' )
		.then( function( snapshot ) {
			loadTemplate( tplPartnersFile({ partner: snapshot.val() }) );
		});

	// Init Events
	$( 'body' )
		.off( 'click', '.main-edit' )
		.on( 'click', '.main-edit', function( e ) {
			e.preventDefault();
			window.location.hash = '#/partners/edit/' + id + '/';
		});

	$( 'body' )
		.off( 'click', '.main-delete' )
		.on( 'click', '.main-delete', function( e ) {
			e.preventDefault();
			window.location.hash = '#/partners/delete/' + id + '/';
		});

	$( 'body' )
		.off( 'click', '.main-accounting' )
		.on( 'click', '.main-accounting', function( e ) {
			e.preventDefault();
			window.location.hash = '#/partners/view/' + id + '/accounting/';
		});		
}
