// Helpers
import { db, user, dynamicTable, loadTemplate, files } from 'helpers.js';

// Templates
import tplBeneficiariesForm from 'templates/beneficiaries/form.html';

export default function viewBeneficiariesForm( id ) {
	var tableFamily;
	var tableAddress;
	var tableEmail;
	var tablePhone;
	var tableAttachment;

	if ( ! user.isLogged() ) {
		return false;
	}

	// Init Dynamic Tables
	tableFamily = new dynamicTable( 'table-family' ).init();
	tableAddress = new dynamicTable( 'table-address' ).init();
	tableEmail = new dynamicTable( 'table-email' ).init();
	tablePhone = new dynamicTable( 'table-phone' ).init();
	tableAttachment = new dynamicTable( 'table-attachment' ).init();

	// Load Template
	db( '/beneficiaries/' + id + '/' )
		.once( 'value' )
		.then( function( snapshot ) {
			loadTemplate(
				tplBeneficiariesForm({ beneficiary: snapshot.val() })
			);
		});

	// Init Files Loaders
	new files( 'attachment', 'beneficiaries' ).init();

	// Init Events
	$( 'body' )
		.off( 'click', '.main-save' )
		.on( 'click', '.main-save', function( e ) {
			e.preventDefault();
			save();
		});

	$( 'body' )
		.off( 'click', '.main-cancel' )
		.on( 'click', '.main-cancel', function( e ) {
			e.preventDefault();
			cancel();
		});

	// Functions
	function save() {
		var newBeneficiary;
		var key;

		var data = {
			'name': $( 'input[name=name]' ).val(),
			'surname': $( 'input[name=surname]' ).val(),
			'dni': $( 'input[name=dni]' ).val(),
			'cuil': $( 'input[name=cuil]' ).val(),
			'birthdate': $( 'input[name=birthdate]' ).val(),
			'civilStatus': $( 'select[name=civilStatus]' ).val(),
			'job': $( 'input[name=job]' ).val(),
			'social': $( 'input[name=social]' ).val(),
			'home': $( 'textarea[name=home]' ).val(),
			'benefit': {
				'dateStart': $( 'input[name=benefitDateStart]' ).val(),
				'dateEnd': $( 'input[name=benefitDateEnd]' ).val(),
				'status': $( 'select[name=benefitStatus]' ).val(),
			},
			'career': {
				'career': $( 'input[name=careerCareer]' ).val(),
				'title': $( 'input[name=careerTitle]' ).val(),
				'establishment': $( 'input[name=careerEstablishment]' ).val(),
				'objectives': $( 'textarea[name=careerObjectives]' ).val(),
			},
			'studies': {
				'establishment': $( 'input[name=studiesEstablishment]' ).val(),
				'title': $( 'input[name=studiesTitle]' ).val(),
				'penndings': $( 'input[name=studiesPenndings]' ).val(),
			},
			'contact': {
				'phones': tablePhone.getValues(),
				'emails': tableEmail.getValues(),
				'addresses': tableAddress.getValues(),
			},
			'family': tableFamily.getValues(),
			'observations': $( 'textarea[name=observations]' ).val(),
			'files': tableAttachment.getValues(),
		};

		if ( undefined !== id ) {
			newBeneficiary = db( '/beneficiaries/' + id + '/' );

		} else {
			newBeneficiary = db( '/beneficiaries/' ).push();
		}

		key = newBeneficiary.key;

		newBeneficiary.set( data ).then( function() {
			window.location.hash = '#/beneficiaries/view/' + key + '/';
		});
	}

	function cancel() {
		if ( undefined !== id ) {
			window.location.hash = '#/beneficiaries/view/' + id + '/';

		} else {
			window.location.hash = '#/beneficiaries/';
		}
	}
}
