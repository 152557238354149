import { breadcrumb } from 'helpers.js';

// Views
import viewBeneficiaries from 'views/beneficiaries/list.js';
import viewBeneficiariesDelete from 'views/beneficiaries/delete.js';
import viewBeneficiariesFile from 'views/beneficiaries/file.js';
import viewBeneficiariesForm from 'views/beneficiaries/form.js';
import viewBeneficiariesAccounting from 'views/beneficiaries/moves.js';

export default function routeSystem() {
	var route = window.Sammy.apps.body;

	// Beneficiaries
	route.get( '#/beneficiaries/', function() {
		viewBeneficiaries();
		breadcrumb([ {
			n: 'Beneficiarios',
		} ]);
	});

	route.get( '#/beneficiaries/add/', function() {
		viewBeneficiariesForm();
		breadcrumb([ {
			u: '#/beneficiaries/',
			n: 'Beneficiarios',
		}, {
			n: 'Agregar',
		} ]);
	});

	route.get( '#/beneficiaries/view/:id/', function() {
		viewBeneficiariesFile( this.params.id );
		breadcrumb([ {
			u: '#/beneficiaries/',
			n: 'Beneficiarios',
		}, {
			n: 'Ficha',
		} ]);
	});

	route.get( '#/beneficiaries/edit/:id/', function() {
		viewBeneficiariesForm( this.params.id );
		breadcrumb([ {
			u: '#/beneficiaries/',
			n: 'Beneficiarios',
		}, {
			n: 'Editar',
		} ]);
	});

	route.get( '#/beneficiaries/delete/:id/', function() {
		viewBeneficiariesDelete( this.params.id );
		breadcrumb([ {
			u: '#/beneficiaries/',
			n: 'Beneficiarios',
		}, {
			n: 'Eliminar',
		} ]);
	});

	route.get( '#/beneficiaries/view/:id/accounting/', function() {
		viewBeneficiariesAccounting( this.params.id );
		breadcrumb([ {
			u: '#/beneficiaries/',
			n: 'Beneficiarios',
		}, {
			u: '#/beneficiaries/view/' + this.params.id + '/',
			n: 'Ficha',
		}, {
			n: 'Contabilidad',
		} ]);
	});
}
