var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom\">\n	<h1 class=\"h2\">Agregar de Padrino</h1>\n\n	<div class=\"mr-2\">\n		<button class=\"btn btn-sm btn-outline-success main-save\">Guardar</button>\n		<button class=\"btn btn-sm btn-outline-secondary main-cancel\">Cancelar</button>\n	</div>\n</div>\n\n\n<div class=\"col-md-8 order-md-1\">\n<form class=\"needs-validation was-validated\">\n	<h4 class=\"mb-3\">General</h4>\n	\n	<div class=\"row\">\n		<div class=\"col-md-12 mb-3\">\n			<label for=\"name\">Nombre o Razón Social</label>\n			<input type=\"text\" class=\"form-control\" name=\"name\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"partner") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" required>\n		</div>\n	</div>\n	\n	<div class=\"row\">\n		<div class=\"col-md-6 mb-3\">\n			<label for=\"tel\">Teléfono</label>\n			<input type=\"text\" class=\"form-control\" id=\"tel\" name=\"tel\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"partner") : depth0)) != null ? lookupProperty(stack1,"tel") : stack1), depth0))
    + "\">\n		</div>\n	\n		<div class=\"col-md-6 mb-3\">\n			<label for=\"firstName\">Email</label>\n			<input type=\"email\" class=\"form-control\" name=\"email\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"partner") : depth0)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + "\">\n		</div>\n	</div>\n\n	<div class=\"row\">\n		<div class=\"col-md-12 mb-3\">\n			<label for=\"address\">Dirección</label>\n			<input type=\"text\" class=\"form-control\" name=\"address\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"partner") : depth0)) != null ? lookupProperty(stack1,"address") : stack1), depth0))
    + "\">\n		</div>\n	</div>\n\n	<br>\n	<br>\n	<h4 class=\"mb-3\">Donación</h4>\n	\n	<div class=\"row\">\n		<div class=\"col-md-4 mb-3\">\n			<label for=\"donateMethod\">Forma de Donación Habitual</label>\n			<select class=\"custom-select d-block w-100\" name=\"donateMethod\" required value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"partner") : depth0)) != null ? lookupProperty(stack1,"donate") : stack1)) != null ? lookupProperty(stack1,"method") : stack1), depth0))
    + "\">\n				<option value=\"\">Elija</option>\n				<option>Efectivo</option>\n				<option>Cheque</option>\n				<option>Depósito Mutual Argentino</option>\n				<option>Depósito Mutual Central</option>\n			</select>\n		</div>\n	</div>\n\n	<div class=\"row\">\n		<div class=\"col-md-6 mb-3\">\n			<label for=\"cuit\">DNI / CUIT</label>\n			<input type=\"text\" class=\"form-control\" name=\"cuit\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"partner") : depth0)) != null ? lookupProperty(stack1,"donate") : stack1)) != null ? lookupProperty(stack1,"cuit") : stack1), depth0))
    + "\">\n		</div>\n\n		<div class=\"col-md-6 mb-3\">\n			<label for=\"iva\">Condición frente al IVA</label>\n			<select class=\"custom-select d-block w-100\" name=\"iva\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"partner") : depth0)) != null ? lookupProperty(stack1,"donate") : stack1)) != null ? lookupProperty(stack1,"iva") : stack1), depth0))
    + "\">\n				<option value=\"\">Elija</option>\n				<option>Consumidor Final</option>\n				<option>IVA Responsable Inscripto</option>\n				<option>IVA Sujeto Exento</option>\n				<option>Responsable Monotributo</option>\n				<option>Otro</option>\n			</select>\n		</div>\n	</div>\n\n\n\n\n	<br>\n	<button class=\"btn btn-success main-save\">Guardar</button>\n	<button class=\"btn btn-secondary main-cancel\">Cancelar</button>\n</form>\n</div>\n\n\n<br>\n<br>\n<br>";
},"useData":true});