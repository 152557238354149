var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom\">\n	<h1 class=\"h2\">Eliminar Informe a padrinos</h1>\n</div>\n\n<h2>¿Esta seguro que desea eliminar el informe?</h2>\n\n<div class=\"ficha-beneficiario\">\n\n<ul>\n	<li><strong>Fecha del reporte:</strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"report") : depth0)) != null ? lookupProperty(stack1,"date") : stack1), depth0))
    + "</li>\n	<li><strong>Período:</strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"report") : depth0)) != null ? lookupProperty(stack1,"period") : stack1), depth0))
    + "</li>\n	<li><strong>Resumen:</strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"report") : depth0)) != null ? lookupProperty(stack1,"summary") : stack1), depth0))
    + "</li>\n</ul>\n\n</div>\n\n<button class=\"btn btn-danger main-delete\">Borrar</button>\n<button class=\"btn btn-secondary main-cancel\">Cancelar</button>";
},"useData":true});