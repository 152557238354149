var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<tr data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":24,"column":15},"end":{"line":24,"column":22}}}) : helper)))
    + "\">\n			<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"surname") || (depth0 != null ? lookupProperty(depth0,"surname") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"surname","hash":{},"data":data,"loc":{"start":{"line":25,"column":7},"end":{"line":25,"column":18}}}) : helper)))
    + ", "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":25,"column":20},"end":{"line":25,"column":28}}}) : helper)))
    + "</td>\n			<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"dni") || (depth0 != null ? lookupProperty(depth0,"dni") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dni","hash":{},"data":data,"loc":{"start":{"line":26,"column":7},"end":{"line":26,"column":14}}}) : helper)))
    + "</td>\n			<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"career") || (depth0 != null ? lookupProperty(depth0,"career") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"career","hash":{},"data":data,"loc":{"start":{"line":27,"column":7},"end":{"line":27,"column":17}}}) : helper)))
    + "</td>\n			<td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"benefitStatus") || (depth0 != null ? lookupProperty(depth0,"benefitStatus") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"benefitStatus","hash":{},"data":data,"loc":{"start":{"line":28,"column":7},"end":{"line":28,"column":24}}}) : helper)))
    + "</td>\n			<td></td>\n		</tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom\">\n	<h1 class=\"h2\">Beneficiarios</h1>\n\n	<div class=\"btn-toolbar mb-2 mb-md-0\">\n		<div class=\"btn-group mr-2\">\n			<button id=\"add-beneficiary\" class=\"btn btn-sm btn-outline-primary\">Agregar</button>\n		</div>\n	</div>\n</div>\n\n<h2>Activos</h2>\n<table class=\"table table-hover table-striped\" id=\"beneficiaries-list\">\n	<thead class=\"thead-dark\">\n		<tr>\n			<th scope=\"col\">Nombre</th>\n			<th scope=\"col\">DNI</th>\n			<th scope=\"col\">Carrera</th>\n			<th scope=\"col\">Estado</th>\n			<th scope=\"col\">&nbsp;</th>\n		</tr>\n	</thead>\n	<tbody>\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"beneficiariesActives") || (depth0 != null ? lookupProperty(depth0,"beneficiariesActives") : depth0)) != null ? helper : alias2),(options={"name":"beneficiariesActives","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":2},"end":{"line":31,"column":27}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"beneficiariesActives")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "	</tbody>\n</table>\n\n<br>\n<br>\n<br>\n<h2>Otros</h2>\n<table class=\"table table-hover table-striped\" id=\"beneficiaries-list\">\n	<thead class=\"thead-dark\">\n		<tr>\n			<th scope=\"col\">Nombre</th>\n			<th scope=\"col\">DNI</th>\n			<th scope=\"col\">Carrera</th>\n			<th scope=\"col\">Estado</th>\n			<th scope=\"col\">&nbsp;</th>\n		</tr>\n	</thead>\n	<tbody>\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"beneficiariesOthers") || (depth0 != null ? lookupProperty(depth0,"beneficiariesOthers") : depth0)) != null ? helper : alias2),(options={"name":"beneficiariesOthers","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":2},"end":{"line":58,"column":26}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"beneficiariesOthers")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "	</tbody>\n</table>\n";
},"useData":true});